import { createContainer } from 'unstated-next'
import client from '../../../lib/client'
import useSWR from 'swr'
import { PROFILE_KEY } from '~/lib/SwrKeys'

const fetchProfile = async () => {
  const { fullname, firstname, email, tenant, keys, timezone } =
    await client.account.get()

  return {
    email,
    firstName: firstname,
    fullName: fullname,
    timezone,
    apiKeys: keys.map(
      ({ id, client_id: apiKey, client_secret: apiSecret, description }) => ({
        id,
        apiKey,
        apiSecret,
        description,
      }),
    ),
    organisation: tenant.name,
  }
}

export function useProfile() {
  return useSWR([PROFILE_KEY], fetchProfile, {
    revalidateOnFocus: false,
  })
}

const ProfileContainer = createContainer(useProfile)

export default ProfileContainer
