import { createContainer } from 'unstated-next'
import { useToasts } from 'react-toast-notifications'
import useSWR from 'swr'
import { ISetting } from '../../Settings.types'
import client from '../../../../lib/client'
import {
  ADD_VAULT_KEY,
  REMOVE_VAULT_KEY,
  REVEAL_VAULT_KEY,
  VAULT_SETTINGS_KEY,
} from '~/lib/SwrKeys'
import useSWRMutation from 'swr/mutation'

const fetchVaultKeys = (): Promise<ISetting[]> => {
  return client.vault.keys() as Promise<ISetting[]>
}

const fetchVaultSetting = async (
  _url,
  { arg }: { arg: { key: string } },
): Promise<ISetting> => {
  const { key } = arg
  const value = (await client.vault.get(key)) as Promise<ISetting>
  return {
    key,
    value: typeof value === 'string' ? value : JSON.stringify(value),
  }
}

const addVaultKeyFn = async (
  _url,
  { arg }: { arg: { key: string; value: string } },
) => {
  const { key, value } = arg
  await client.vault.set(key, value)
  return { key, value }
}

const removeVaultKeyFn = async (_url, { arg }: { arg: { key: string } }) => {
  const { key } = arg
  await client.vault.remove(key)
  return { key }
}

export const useVault = () => {
  const { addToast } = useToasts()

  const vault = useSWR(VAULT_SETTINGS_KEY, fetchVaultKeys, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  })

  const addVaultKey = useSWRMutation([ADD_VAULT_KEY], addVaultKeyFn, {
    onSuccess: ({ key }) => {
      const index = vault?.data.findIndex(pair => pair.key === key)
      const clone = [...vault.data]

      index === -1 ? clone.push({ key }) : (clone[index] = { key })

      vault.mutate(clone, { revalidate: false })
      addToast('Successfully saved setting', { appearance: 'success' })
    },
    onError: () => {
      addToast('Failed to save setting', { appearance: 'error' })
    },
    throwOnError: false,
  })

  const removeVaultKey = useSWRMutation([REMOVE_VAULT_KEY], removeVaultKeyFn, {
    onSuccess: () => {
      vault.mutate()
      addToast('Successfully removed setting', { appearance: 'success' })
    },
    onError: () => {
      addToast('Failed to remove setting', { appearance: 'error' })
    },
    throwOnError: false,
  })

  const revealVaultValue = useSWRMutation(
    [REVEAL_VAULT_KEY],
    fetchVaultSetting,
    {
      onSuccess: ({ key, value }) => {
        const newData =
          vault?.data?.length > 0
            ? vault?.data?.map(item => {
                return item.key === key ? { ...item, value } : item
              })
            : []
        vault.mutate(newData, { revalidate: false })
      },
    },
  )

  return {
    vault,
    addVaultKey,
    removeVaultKey,
    revealVaultValue,
  }
}

export default useVault
export const VaultContainer = createContainer(useVault)
