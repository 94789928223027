import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import client from '~/lib/client'
import { useModal } from 'react-modal-hook'
import { useFormik } from 'formik'
import { v4 as createUUID } from 'uuid'
import {
  Button,
  Icon,
  Form,
  Modal,
  Segment,
  Message,
  Tooltip,
} from '@waylay/react-components'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { IErrorResponse, IResponse } from '~/lib/types'
import { IfRejected } from '~/components/Common/SWRHelpers'
import useSWRMutation from 'swr/mutation'
import { CREATE_CONSTRAINT_KEY } from '~/lib/SwrKeys'

const createConstraintFn = async (_url, { arg }: { arg: { values: any } }) => {
  const { values } = arg
  return await client.constraints.create(values)
}

export default function CreateConstraint() {
  const navigate = useNavigate()

  const [showModal, hideModal] = useModal(() => {
    const constraintState = useSWRMutation(
      CREATE_CONSTRAINT_KEY,
      createConstraintFn,
      {
        onSuccess: (response: IResponse) => {
          navigate(`/resourceconstraints/${response.entity.id}`)
        },
      },
    )
    const formik = useFormik({
      initialValues: {
        id: createUUID(),
        name: '',
        attributes: [],
      },
      onSubmit: values => constraintState.trigger({ values }),
      validationSchema: Yup.object().shape({
        name: Yup.string().required(),
      }),
    })

    return (
      <Modal
        isOpen
        onRequestClose={hideModal}
        onAfterClose={() => formik.resetForm()}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Segment.Group
            css={css`
              width: 600px;
            `}
          >
            <Segment.Header>Create constraint</Segment.Header>
            <Segment>
              <label htmlFor="name">Name</label>
              <FluidGroup>
                <Form.Input
                  name="name"
                  fluid
                  autoFocus
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </FluidGroup>
              <label htmlFor="id">Identifier</label>
              <FluidGroup>
                <Form.Input
                  name="id"
                  fluid
                  value={formik.values.id}
                  onChange={formik.handleChange}
                />
                <Tooltip content="This is the unique identifier of the resource">
                  <Form.Input.Adornment
                    right
                    css={css`
                      display: inline-flex;
                    `}
                  >
                    <Icon name="help" />
                  </Form.Input.Adornment>
                </Tooltip>
              </FluidGroup>
              <div
                css={css`
                  margin-top: 0.5rem;
                `}
              >
                {Object.values(formik.errors)[0] && (
                  <Message kind="danger">
                    {Object.values(formik.errors)[0]}
                  </Message>
                )}
                <IfRejected state={constraintState}>
                  {(error: IErrorResponse) => (
                    <Message kind="danger">{error.response.data.error}</Message>
                  )}
                </IfRejected>
              </div>
            </Segment>
            <Modal.Actions>
              <Button outline kind="secondary" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                kind="primary"
                loading={constraintState.isMutating}
                disabled={constraintState.isMutating}
                onClick={formik.handleSubmit}
              >
                Create constraint
              </Button>
            </Modal.Actions>
          </Segment.Group>
        </Form>
      </Modal>
    )
  }, [])

  return {
    showModal,
    hideModal,
  }
}

const FluidGroup = styled(Form.Input.Group)`
  display: inline-flex;
  align-items: center;
  width: 100%;
`
