import React, { Fragment } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { get, isEmpty } from 'lodash-es'
import {
  Collapsible,
  Form,
  Icon,
  Message,
  Segment,
  Toggle,
} from '@waylay/react-components'
import ResourceSelect from '~/components/Common/ResourceSelect'
import TemplateSelect from '~/components/Common/TemplateSelect'
import {
  TaskTags,
  TaskTypeConfiguration,
  TaskTypeSelector,
} from '~/components/Common/TaskCreate'
import { SWRMutationResponse } from 'swr/mutation'

export type StepKey = 'task_configuration' | 'variables_input'

interface ITaskConfigurationProps {
  task: any
  formik: any
  propTemplate: any
  setSelectedTemplate: any
  key: StepKey
}

export const TaskConfiguration = ({
  task,
  formik,
  propTemplate,
  setSelectedTemplate,
  key,
}: ITaskConfigurationProps) => {
  return (
    <Fragment key={key}>
      <Segment.Header>
        {task.ID ? 'Edit task' : 'Create new task'}
      </Segment.Header>
      <Segment>
        <label htmlFor="name">Name*</label>
        <Form.Input.Group fluid>
          <Form.Input
            id="name"
            autoFocus
            fluid
            defaultValue={formik.values.name}
            onChange={formik.handleChange}
          />
        </Form.Input.Group>

        {!propTemplate && (
          <div style={{ marginTop: '0.5rem' }}>
            <label htmlFor="template">Template*</label>
            <TemplateSelect
              template={formik.values.template}
              onChange={({ value }) => {
                setSelectedTemplate(value)
                formik.setErrors({})
                formik.setFieldValue('template', value)
                formik.setFieldValue('variables', {})
              }}
            />
          </div>
        )}

        <div style={{ marginTop: '0.5rem' }}>
          <label htmlFor="resource">Resource</label>
          <ResourceSelect
            resource={formik.values.resource}
            onChange={({ value }) => formik.setFieldValue('resource', value)}
          />
        </div>
        <TaskTags
          setTags={tags => formik.setFieldValue('tags', tags)}
          tags={formik.values.tags}
        />
        <TaskTypeSelector
          taskType={formik.values.type}
          setError={formik.setErrors}
          setTaskType={type => formik.setFieldValue('type', type)}
        />
      </Segment>
      <TaskTypeConfiguration
        schedule={formik.values.schedule}
        scheduleChange={schedule => formik.setFieldValue('schedule', schedule)}
        frequency={formik.values.frequency}
        frequencyChange={frequency =>
          formik.setFieldValue('frequency', frequency)
        }
        taskType={formik.values.type}
      />
      <Collapsible>
        {({ isOpen, toggle, style }) => (
          <>
            <Segment.Header
              onClick={() => toggle()}
              style={{ userSelect: 'none', cursor: 'pointer' }}
            >
              <Icon
                name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              />{' '}
              Advanced configuration
            </Segment.Header>
            <Segment style={style} padding={isOpen ? '1rem' : 0}>
              <ToggleField>
                <Toggle
                  id="parallel"
                  name="parallel"
                  checked={formik.values.parallel}
                  onChange={formik.handleChange}
                />
                <label htmlFor="parallel">Execute sensors in parallel</label>
              </ToggleField>
              <ToggleField>
                <Toggle
                  id="resetObservations"
                  name="resetObservations"
                  checked={formik.values.resetObservations}
                  onChange={formik.handleChange}
                />
                <label htmlFor="resetObservations">
                  Reset observations on each invocation
                </label>
              </ToggleField>
              <ToggleField>
                <Toggle
                  id="gatesNeedFullObservation"
                  name="gatesNeedFullObservation"
                  checked={formik.values.gatesNeedFullObservation}
                  onChange={formik.handleChange}
                />
                <label htmlFor="gatesNeedFullObservation">
                  Only evaluate gates when all inputs are observed
                </label>
              </ToggleField>
            </Segment>
          </>
        )}
      </Collapsible>
    </Fragment>
  )
}

export const Errors = ({
  errors: _errors = {},
  asyncState,
}: {
  errors: Record<string, any>
  asyncState?: SWRMutationResponse
}) => {
  const { variables = {}, ...errors } = _errors
  const hasErrors = Boolean(!isEmpty(_errors) || asyncState.error)

  if (!hasErrors) {
    return null
  }

  return (
    <Segment>
      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        {Object.keys(errors).map(field => (
          <FormError key={field} error={errors[field]} />
        ))}
        {Object.keys(variables).map(field => (
          <FormError key={field} error={variables[field]} />
        ))}
        {asyncState.error && <FormError error={asyncState.error} />}
      </div>
    </Segment>
  )
}

const FormError = ({ error }) => {
  if (!error) {
    return null
  }

  const message =
    typeof error === 'string'
      ? error
      : get(error, 'response.data.error') || error.message

  return (
    <div
      css={css`
        margin-top: 0.5rem;
      `}
    >
      <Message kind="danger">{message}</Message>
    </div>
  )
}
const ToggleField = styled(Form.Field)`
  display: flex;
  align-items: center;

  > label {
    margin-left: 0.5em;
    user-select: none;
  }
`
