import { get } from 'lodash-es'
import client from '../../../../../lib/client'
import useSWR from 'swr'
import { IResourceType } from '../../../Resources.types'
import { ConstraintAttribute } from '~/components/ResourceConstraints/Constraints.types.'

const fetchResourceTypeFn = async (id: string) => {
  const response = await client.types.get(id)
  return response
}

const fetchConstraintsFn = async (id: string) => {
  const response = await client.types.constraints(id)
  return response
}

const useAttributes = (resourceTypeId: string) => {
  const { data: constraintsFetch } = useSWR(
    [`attributes`, resourceTypeId],
    async ([, id]) => await fetchConstraintsFn(id),
    { revalidateOnFocus: false },
  )

  const { data: resourceType } = useSWR(
    [`resource-types`, resourceTypeId],
    async ([, id]) => await fetchResourceTypeFn(id),
    { revalidateOnFocus: false },
  )

  const attributes = get(
    constraintsFetch,
    'attributes',
    [],
  ) as ConstraintAttribute[]

  const filteredAttributes = attributes
    .filter(attr => attr.required)
    .filter(attr => attr.name !== 'id')

  return {
    filteredAttributes,
    resourceType: resourceType as IResourceType,
  }
}

export default useAttributes
