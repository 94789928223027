import React from 'react'
import TemplateSelect from '~/components/Common/TemplateSelect'
import { IPropertyInput } from '../../types'

const TemplateField = ({ property, value, setFieldValue }: IPropertyInput) => {
  const { name, defaultValue } = property

  const handleChange = ({ value }) => {
    setFieldValue(name, value)
  }

  return (
    <TemplateSelect
      template={value}
      placeholder={defaultValue}
      onChange={handleChange}
    />
  )
}

export default TemplateField
