import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  Button,
  Form,
  Modal,
  Segment,
  Label,
  Icon,
  Message,
  colors,
  concrete,
  carbon,
  Tooltip,
} from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import {
  IfFulfilled,
  IfPending,
  IFMutationInitial,
  IfRejected,
} from '../../Common/../../Common/SWRHelpers'
import {
  ComponentLocation,
  InputProperty,
  PlugType,
  PlugTypeSingular,
} from '../../Common/Types'
import { get, isEmpty } from 'lodash-es'
import { useFormik } from 'formik'
import PropertyInput from '~/components/Tasks/Editor/PropertyInput'
import { IPlugProperty, PropertyFormatType } from '~/lib/types'
import { CustomFunctionContainer } from '../../Common/useCustomFunctions'
import { useFlag } from '~/lib/flags'
import DescriptorProperties from './Descriptor/DescriptorProperties'
import DebugLogs from './DebugLogs'
import Loading, { ILoadingConfig } from '~/components/Common/Loading/Loading'

const debugLoaderConfig = (): ILoadingConfig => ({
  bars: [
    {
      nbBars: 1,
    },
  ],
  loaderHeight: 20,
})

export const debugLoader: ILoadingConfig = debugLoaderConfig()

interface IDebugViewProps {
  type: PlugTypeSingular | PlugType
  name: string
  isDisabled?: boolean
  componentLocation?: ComponentLocation
  version: string
  properties: IPlugProperty[] | InputProperty[]
  handleSubmit: Function
  initialValues?: object
  state: any
  children?: any
  tags?: Array<{ name: string; color: string }>
  resetOnClose?: boolean
  setIsModalOpen?: Function
  descriptors?: any
}

export function useDebugModal(
  {
    resetOnClose = true,
    type,
    name,
    isDisabled,
    version,
    properties,
    handleSubmit,
    state,
    initialValues,
    componentLocation,
    setIsModalOpen,
  }: IDebugViewProps,
  dependencies: any[],
) {
  const [emptyValues, setEmptyValues] = useState({})
  const edgeMode = useFlag('edgeMode', false)
  const { isSaveEventPending } =
    edgeMode || componentLocation !== ComponentLocation.HEADER
      ? { isSaveEventPending: false }
      : CustomFunctionContainer.useContainer()

  const [shouldShowDescriptor, setShouldShowDescriptor] = useState(false)
  const [descriptorName, setDescriptorName] = useState('')

  useEffect(() => {
    const emptyValuesNew = (properties as IPlugProperty[])?.reduce(
      (acc, property) => {
        if (property.mandatory && !property.defaultValue) {
          acc[property.name] = ''
        }
        return acc
      },
      {},
    )
    setEmptyValues(emptyValuesNew)
  }, [properties])

  /*
   * $config includes properties that are included in this form but should not
   * be returned in the plugProperties (e.g. node resource)
   */
  const onSubmit = useCallback((values: any) => {
    const { $config, ...properties } = values
    handleSubmit(properties, $config)
  }, [dependencies, name, version, type] || [])

  const formik = useFormik({
    initialValues: initialValues || emptyValues,
    onSubmit,
  })

  const [showModal, hideModal] = useModal(() => {
    const onRequestClose = () => {
      typeof setIsModalOpen === 'function' && setIsModalOpen(false)
      hideModal()

      if (state) {
        // reset the modal state
        state.reset()
      }

      if (resetOnClose) {
        formik.resetForm()
      }
    }

    return (
      <FullscreenModal
        isOpen
        onRequestClose={onRequestClose}
        onAfterOpen={() =>
          typeof setIsModalOpen === 'function' && setIsModalOpen(false)
        }
      >
        <Layout>
          <Segment.Group
            css={css`
              width: 600px;
              align-self: start;
            `}
          >
            <Segment.Header>
              <Title>Result</Title>
              <TitleRight>
                <StateLabel state={state} type={type as PlugType} />
              </TitleRight>
            </Segment.Header>
            <Result state={state} />
            <Segment.Header>
              <Title>Logs</Title>
            </Segment.Header>
            <DebugLogs state={state} />
          </Segment.Group>
          <Form onSubmit={formik.handleSubmit}>
            <Segment.Group
              css={css`
                align-self: start;
              `}
            >
              <Segment.Header>
                <Title>Properties</Title>
                <TitleRight>
                  {name} · {version}
                </TitleRight>
              </Segment.Header>
              <Segment>
                {properties.length === 0 && 'No Properties.'}
                <FormGrid>
                  {(properties as IPlugProperty[])?.map(
                    (property: IPlugProperty) => {
                      return (
                        <Fragment key={property.name}>
                          {property?.format?.type !==
                            PropertyFormatType.AiTemplateDescriptor &&
                            property?.format?.type !==
                              PropertyFormatType.AiPluginDescriptor && (
                              <label htmlFor={property.name}>
                                {property.name}
                              </label>
                            )}
                          <PropertyInput
                            key={property.name}
                            property={property}
                            value={formik.values[property.name]}
                            setFieldValue={formik.setFieldValue}
                            values={formik.values}
                            setShouldShowDescriptor={setShouldShowDescriptor}
                            setDescriptorName={setDescriptorName}
                          />
                        </Fragment>
                      )
                    },
                  )}
                </FormGrid>
              </Segment>
              <Modal.Actions>
                <Button outline kind="secondary" onClick={onRequestClose}>
                  Cancel
                </Button>

                <TestButton
                  state={state}
                  type={type as PlugType}
                  isSaveEventPending={isSaveEventPending}
                  isDisabled={isDisabled}
                />
              </Modal.Actions>
            </Segment.Group>
          </Form>
          <DescriptorProperties
            shouldShowDescriptor={shouldShowDescriptor}
            descriptorName={descriptorName}
            setFieldValue={formik.setFieldValue}
            setShouldShowDescriptor={setShouldShowDescriptor}
            property={properties.find(
              (property: IPlugProperty) => property.name === descriptorName,
            )}
            descriptor={formik.values[descriptorName]}
          />
        </Layout>
      </FullscreenModal>
    )
  }, [state, properties, formik])

  return { showModal, hideModal, formik }
}

const StateLabel = ({ state, type }: { state: any; type: PlugType }) => (
  <>
    {(type === PlugType.Sensor || PlugTypeSingular.Sensor) && (
      <>
        <IFMutationInitial state={state}>
          <Label size="small" outline>
            No State
          </Label>
        </IFMutationInitial>
        <IfPending state={state}>
          <Label size="small" outline>
            No State
          </Label>
        </IfPending>
        <IfFulfilled state={state}>
          {data => (
            <Label size="small" outline color={data.state ? 'blue' : ''}>
              {get(data, 'state', 'No State')}
            </Label>
          )}
        </IfFulfilled>
      </>
    )}
    <IfRejected state={state}>
      <Label size="small" color="red">
        <Icon name="clear" /> Failed
      </Label>
    </IfRejected>
  </>
)

const Result = ({ state }: { state }) => (
  <Segment>
    <IFMutationInitial state={state}>Not yet executed.</IFMutationInitial>
    <IfPending state={state}>
      <Loading {...debugLoader} />
    </IfPending>
    <IfFulfilled state={state}>
      {data => {
        if (data.message) {
          return (
            <Message title="Debug message" kind="info">
              {data.message}
            </Message>
          )
        }

        return isEmpty(data.rawData) ? (
          'No raw data.'
        ) : (
          <CodeBlock>{JSON.stringify(data.rawData, null, 2)}</CodeBlock>
        )
      }}
    </IfFulfilled>
    <IfRejected state={state}>
      {error => (
        <Message title={error.message} kind="danger">
          {get(error, 'response.data.error')}
        </Message>
      )}
    </IfRejected>
  </Segment>
)

const TestButton = ({
  state,
  type,
  isSaveEventPending = false,
  isDisabled = false,
}: {
  state: any
  type: PlugType
  isSaveEventPending?: boolean
  isDisabled?: boolean
}) => {
  const handleClick = () => {
    state.reset()
  }

  const label = type.endsWith('s') ? getPlugTypeLabel(type) : type
  return (
    <>
      {isSaveEventPending ? (
        <Tooltip content="Deploying in progress" placement="top">
          <div>
            <Button type="submit" loading kind="primary" disabled={isDisabled}>
              Test {label === 'sensor' ? 'plugin' : label}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          type="submit"
          disabled={state.isLoading || isDisabled}
          loading={state.isLoading || isSaveEventPending}
          kind="primary"
          onClick={handleClick}
        >
          Test {label === 'sensor' ? 'plugin' : label}
        </Button>
      )}
    </>
  )
}

function getPlugTypeLabel(type: PlugType): string {
  switch (type) {
    case PlugType.Sensor:
      return 'sensor'
    case PlugType.Actuator:
      return 'actuator'
    case PlugType.Transformer:
      return 'transformer'
    default:
      return 'plugin'
  }
}

const DebugView = (options: IDebugViewProps) => {
  const dependencies = [options.handleSubmit]
  const { showModal, hideModal, formik } = useDebugModal(options, dependencies)
  return options.children({ showModal, hideModal, formik })
}

const Title = styled.span`
  flex: 1;
  font-weight: bold;
`

export const TitleRight = styled.div`
  color: ${carbon};
  font-size: 0.8em;

  > div {
    margin-right: 0.5rem;
  }
`

const Layout = styled.div`
  display: flex;

  > ${Segment.Group} {
    margin-right: 2rem;
  }
`

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
`

export const FormGrid = styled(TwoColumnGrid)`
  grid-column-gap: 0.7em;
  grid-row-gap: 0.5em;
`

const FullscreenModal = styled(Modal)`
  max-width: 100%;
`

const CodeBlock = styled.code`
  display: block;
  font-size: 0.9em;
  background: ${colors.withWeight(concrete, 200)};
  border-radius: 4px;
  border: solid 1px ${concrete};
  line-height: 1.5em;
  padding: 1em 0.65em;
  white-space: pre;
  overflow-x: auto;
`

export default DebugView
