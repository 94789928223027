import client from '~/lib/client'
import * as Yup from 'yup'
import { isEmpty } from 'lodash-es'
import { ITemplate, PropertyType } from '~/lib/types'
import { ITaskOptions, ITemplateVariable, TaskVariable } from '../../types'

// export const createOrUpdateTaskDeferred = async (
//   _url,
//   { arg }: { arg: { values: any } },
// ) => {
//   const { values } = arg
//   const { template, options = {}, variables } = values

//   if (options.tags) {
//     const definedTags = options.tags.filter(({ key }) => key) // filter out empty tag keys.
//     const keyValueEntries = definedTags.map(({ key, value }) => [key, value])
//     Object.assign(options, { tags: Object.fromEntries(keyValueEntries) })
//   }

//   if (!isEmpty(variables)) {
//     Object.assign(options, { variables })
//   }

//   const { ID, ...restOptions } = options
//   const taskOptions: Partial<ITaskOptions> = {
//     template,
//     start: true,
//     variables: {},
//     parallel: true,
//     resetObservations: false,
//     ...restOptions,
//   }

//   if (ID) return client.tasks.update(ID, taskOptions)
//   return client.tasks.create(taskOptions)
// }

export const createOrUpdateTaskDeferred = (
  _,
  {
    arg,
  }: {
    arg: {
      template: string | any
      options: Partial<ITaskOptions>
      variables: TaskVariable
    }
  },
) => {
  const { template, options = {}, variables } = arg
  if (options.tags) {
    const definedTags = options.tags.filter(({ key }) => key) // filter out empty tag keys. ('foo': '') is permitted
    const keyValueEntries = definedTags.map(({ key, value }) => [key, value])
    Object.assign(options, { tags: Object.fromEntries(keyValueEntries) })
  }

  if (!isEmpty(variables)) {
    Object.assign(options, { variables })
  }

  const { ID, ...restOptions } = options
  const taskOptions: Partial<ITaskOptions> = {
    template,
    start: true,
    variables: {},
    parallel: true,
    resetObservations: false,
    ...restOptions,
  }

  if (ID) return client.tasks.update(ID, taskOptions)
  return client.tasks.create(taskOptions)
}

export const isTemplateVariable = (
  variables: ITemplateVariable[] | TaskVariable,
): variables is ITemplateVariable[] => {
  return Array.isArray(variables)
}

export const templateFetch = async (selectedTemplate): Promise<ITemplate> =>
  !selectedTemplate
    ? { taskDefaults: [], variables: [] }
    : await (client.templates.get(
        selectedTemplate,
      ) as unknown as Promise<ITemplate>)

export function getYupSchemaFor(
  type: PropertyType,
  mandatory: Boolean,
  errorIdentifier: String,
): Yup.Schema<any> {
  switch (type) {
    case PropertyType.Float:
    case PropertyType.Double:
      return mandatory
        ? Yup.number().required(`${errorIdentifier} is required`)
        : Yup.number()
    case PropertyType.Integer:
    case PropertyType.Long:
      return mandatory
        ? Yup.number()
            .integer(`${errorIdentifier} cannot have decimals`)
            .required(`${errorIdentifier} is required`)
        : Yup.number().integer(`${errorIdentifier} cannot have decimals`)
    case PropertyType.Object:
      return mandatory
        ? Yup.object().required(`${errorIdentifier} is required`)
        : Yup.object()
    case PropertyType.Array:
      return mandatory
        ? Yup.array().required(`${errorIdentifier} is required`)
        : Yup.array()
    case PropertyType.String:
    default:
      return mandatory
        ? Yup.string().required(`${errorIdentifier} is required`)
        : Yup.string()
  }
}
