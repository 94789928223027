import { createContainer } from 'unstated-next'
import { createFileDownload } from '../../lib/util'
import client from '../../lib/client'
import useSWRMutation from 'swr/mutation'

interface IAsyncProps {
  onResolve: () => void
  onReject: (err: Error) => void
}

const removeDeferred = (id: string): Promise<Object> => {
  return client.templates.remove(id)
}

const useTemplate = () => {
  const remove = useSWRMutation(
    'remove-template',
    async (_, { arg }: { arg: { id: string } }) => {
      const { id } = arg
      await removeDeferred(id)
    },
  )

  const download = async template => {
    const filename = `${template.name}.json`
    const templateDownload = await client.templates.get(template.name, {
      format: 'simplified',
    })
    const json = JSON.stringify(templateDownload, null, 2)

    createFileDownload(filename, json)
  }

  const upload = ({ onResolve, onReject }: IAsyncProps) =>
    useSWRMutation(
      'upload-template',
      async (_, { arg }: { arg: { template: any; overwrite?: any } }) => {
        const { template, overwrite } = arg
        return await uploadTemplate(template, overwrite)
      },
      {
        onSuccess: onResolve,
        onError: onReject,
        throwOnError: false,
      },
    )

  const uploadTemplate = (template, overwrite) => {
    if (!template) return
    if (overwrite) return client.templates.update(template.name, template)
    return client.templates.create(template)
  }

  const migrate = ({ onResolve, onReject }: IAsyncProps) =>
    useSWRMutation(
      'migrate-template',
      async (
        _,
        { arg }: { arg: { templateId: string; plugsToBeUpdated: any } },
      ): Promise<Object> => {
        const { templateId, plugsToBeUpdated } = arg
        return await client.templates.patch(
          { ids: templateId },
          {
            operation: 'updatePlugins',
            updates: plugsToBeUpdated.map(upgrade => ({
              name: upgrade.name,
              fromVersion: upgrade.version,
              toVersion: upgrade.newerVersion,
            })),
            reloadTasks: true,
          },
        )
      },
      {
        onSuccess: onResolve,
        onError: onReject,
        throwOnError: false,
      },
    )

  return {
    remove,
    download,
    migrate,
    upload,
  }
}

export default useTemplate
export const TemplateContainer = createContainer(useTemplate)
