import { useEffect, useMemo, useState } from 'react'
import { get, remove, pull, isEqual, omit, pick } from 'lodash-es'
import { createContainer } from 'unstated-next'
import { useImmerReducer } from 'use-immer'
import {
  IPlugProperty,
  IPlugTag,
  IRawDataProperty,
  IWebscriptPlugProperties,
} from '~/lib/types'
import { IPlug } from '~/components/Templates/util'
import {
  PlugType,
  PlugV2Structure,
  PluginStatusOwnership,
} from '../../Common/Types'
import client from '~/lib/client'
import { useLogin } from '~/components/App/LoginContext'
import useSWR from 'swr'
import { RuntimeContainer } from '../../Common/useRuntimes'

interface IChanges {
  payload: boolean
  metadata: boolean
  interface: boolean
}

enum EditorStateType {
  Empty,
  SetStateProperty,
  AddDependency,
  RemoveDependency,
  ToggleEditDependency,
  Replace,
  ToggleEditProperty,
  ToggleEditRawData,
  ToggleEditStates,
  AddState,
  RemoveState,
  AddProperty,
  EditProperty,
  RemoveProperty,
  AddRawData,
  RemoveRawData,
  AddTag,
  RemoveTag,
  SetDocumentation,
  SetDescription,
}

export enum EConfigurationType {
  string = 'string',
  integer = 'integer',
  long = 'long',
  double = 'double',
  float = 'float',
  boolean = 'boolean',
  object = 'object',
}

export enum EConfigurationFormatType {
  enum = 'enum',
  resource = 'resource',
  vault = 'vault',
  duration = 'duration',
  code = 'code',
  url = 'url',
  date = 'date',
}

interface IAction {
  type: EditorStateType
  payload?: any
}

interface IPropFormat {
  type: string
  values?: any[]
  strict: boolean
}
export interface IPluginEditorContextState {
  // keep the initial fetched state from backend in memory for later difference checking
  initialState: Omit<IPluginEditorContextState, 'intialState'>
  runtimeName: string
  runtimeVersion: string
  // keeps track of what entity is being edited currently
  type: PlugType
  pluginOwnershipStatus: PluginStatusOwnership
  // plug related properties
  isDeprecated: boolean
  reloadVersions?: any
  name: string
  author: string
  draftDetails: any
  script: string
  version: string
  versions: string[]
  iconURL: string
  states: string[]
  properties: IPlugProperty[]
  rawData: IRawDataProperty[]
  dependencies: object
  deployStatus: string
  description: string
  tags: string[]
  documentation?: {
    states: Array<{
      name: string
      description?: string
    }>
    input: Array<{ name: string; description?: string }>
    output: Array<{ name: string; description?: string }>
  }

  // editor configuration
  enabledPropertiesEditor: boolean
  enabledDependenciesEditor: boolean
  enabledStatesEditor: boolean
  enabledRawDataEditor: boolean

  // callable functions
  currentState: any
  addDependency: (name: string, version?: string, isPython?: boolean) => void
  removeDependency: (dependency: string) => void
  addTag: (tag: IPlugTag) => void
  removeTag: (name: string) => void
  addProperty: (
    name: string,
    type: string,
    required: boolean,
    format?: IPropFormat,
  ) => void
  editProperty: (
    oldName: string,
    newName: string,
    type: string,
    required: boolean,
    format?: IPropFormat,
  ) => void
  removeProperty: (name: string) => void
  addRawData: (name: string, type: string) => void
  removeRawData: (name: string) => void
  addState: (name: string) => void
  removeState: (name: string) => void
  toggleEditStates: () => void
  toggleEditProperty: () => void
  toggleEditRawData: () => void
  toggleEditDependency: () => void
  toggleWebscriptStatus: () => void
  setWebscriptStatus: (value: boolean) => void
  setStateProperty: (name: keyof IPluginEditorContextState, value: any) => void
  setDocumentation: (name: string, value: string) => void
  setDescription: (value: string) => void
}

const emptyState: Partial<IPluginEditorContextState> = {
  type: PlugType.Sensor,
  states: [],
  properties: [],
  rawData: [],
  versions: [],
  dependencies: {},
  enabledPropertiesEditor: false,
  enabledDependenciesEditor: false,
  enabledStatesEditor: false,
  enabledRawDataEditor: false,
}

function reducer(draft: any, action: IAction) {
  const { type, payload } = action

  switch (type) {
    case EditorStateType.SetStateProperty:
      draft[payload.property] = payload.value
      break
    case EditorStateType.ToggleEditProperty:
      draft.enabledPropertiesEditor = !draft.enabledPropertiesEditor
      break
    case EditorStateType.ToggleEditRawData:
      draft.enabledRawDataEditor = !draft.enabledRawDataEditor
      break
    case EditorStateType.ToggleEditDependency:
      draft.enabledDependenciesEditor = !draft.enabledDependenciesEditor
      break
    case EditorStateType.ToggleEditStates:
      draft.enabledStatesEditor = !draft.enabledStatesEditor
      break
    case EditorStateType.AddState:
      draft.documentation.states.push({ name: payload.name, description: '' })
      draft.states.push(payload.name)
      break
    case EditorStateType.RemoveState:
      remove(draft.documentation.states, {
        name: payload.name,
      })
      pull(draft.states, payload.name)
      break
    case EditorStateType.AddProperty:
      draft.documentation.input.push({ name: payload.name, description: '' })
      draft.properties.push(payload)
      break
    case EditorStateType.EditProperty:
      remove(draft.documentation.input, {
        name: payload.oldName,
      })

      draft.documentation.input.push({
        name: payload.newName,
        description: '',
      })

      remove(draft.properties, { name: payload.oldName })
      draft.properties.push({
        name: payload.newName,
        dataType: payload.dataType,
        mandatory: payload.mandatory,
        format: payload.format,
      })
      break
    case EditorStateType.RemoveProperty:
      remove(draft.documentation.input, { name: payload.name })
      remove(draft.properties, { name: payload.name })
      break
    case EditorStateType.AddRawData:
      draft.documentation.output.push({
        name: payload.name,
        description: '',
      })
      draft.rawData.push(payload)
      break
    case EditorStateType.RemoveRawData:
      remove(draft.documentation.output, {
        name: payload.name,
      })
      remove(draft.rawData, { name: payload.name })
      break
    case EditorStateType.AddDependency:
      if (!payload.isPython) {
        draft.dependencies[payload.name] = payload.version
      } else {
        draft.dependencies.push(payload.name)
      }

      break
    case EditorStateType.RemoveDependency:
      /* eslint-disable @typescript-eslint/no-dynamic-delete */
      if (!payload.isPython) {
        delete draft.dependencies[payload.name]
      } else {
        const dependenciesIndex = draft.dependencies.indexOf(payload.name)
        if (dependenciesIndex > -1) {
          // only splice array when item is found
          draft.dependencies.splice(dependenciesIndex, 1) // 2nd parameter means remove one item only
        }
      }
      /* eslint-enable @typescript-eslint/no-dynamic-delete */
      break

    case EditorStateType.Replace:
      return { ...emptyState, ...payload }
    case EditorStateType.AddTag:
      if (!draft.tags) {
        draft.tags = []
      }

      draft.tags.push(payload)
      break
    case EditorStateType.RemoveTag:
      draft.tags.splice(
        draft.tags.findIndex(tag => tag.name === payload),
        1,
      )
      break
    case EditorStateType.SetDocumentation:
      // eslint-disable-next-line no-case-declarations
      const index = draft.documentation[payload.documentationType].findIndex(
        ({ name, parameter }) =>
          name === payload.name || parameter === payload.name,
      )

      if (index !== -1) {
        draft.documentation[payload.documentationType][index].description =
          payload.value
      }
      // WORKAROUND - name property does not appear in the documentation object from BE, if we don't find the property we will add it.
      if (index === -1) {
        draft.documentation[payload.documentationType].push({
          name: payload.name,
          description: payload.value,
        })
      }
      break
    case EditorStateType.SetDescription:
      draft.metadata.description = payload.value
      break
    default:
      break
  }
}
const fetchPlugVersions = async name => {
  if (name) {
    const versions = await client.registry.plugs.listVersions(name, {
      includeDeprecated: true,
      includeDraft: true,
    })
    return versions
  }
  return []
}

const usePluginEditor = (data: PlugV2Structure): IPluginEditorContextState => {
  const [state, dispatch] = useImmerReducer(reducer, emptyState)
  const [initialState, setInitialState] =
    useState<Partial<IPlug & IWebscriptPlugProperties>>()

  const [changes, setChanges] = useState<IChanges>({
    payload: false,
    metadata: false,
    interface: false,
  })

  useEffect(() => {
    const copyState = omit(state, [
      'enabledPropertiesEditor',
      'enabledDependenciesEditor',
      'enabledStatesEditor',
      'enabledRawDataEditor',
      'description',
      'author',
      'iconURL',
      'documentation',
      'tags',
    ])

    const copyInitialState = omit(initialState, [
      'enabledPropertiesEditor',
      'enabledDependenciesEditor',
      'enabledStatesEditor',
      'enabledRawDataEditor',
      'description',
      'author',
      'iconURL',
      'documentation',
      'tags',
    ])

    const metadataState = pick(state, [
      'author',
      'description',
      'iconURL',
      'tags',
    ])
    const metadataInitialState = pick(initialState, [
      'author',
      'description',
      'iconURL',
      'tags',
    ])

    const interfaceState = pick(state, ['documentation'])
    const interfaceInitialState = pick(initialState, ['documentation'])

    setChanges({
      payload: !isEqual(copyState, copyInitialState),
      metadata: !isEqual(metadataState, metadataInitialState),
      interface: !isEqual(interfaceState, interfaceInitialState),
    })
  }, [initialState, state])

  const { sub } = useLogin()

  const versions = useSWR(
    [
      'versions-key',
      data.details.plug.name,
      data.details.plug.version,
      data.details.draft,
    ],
    async () => await fetchPlugVersions(data.details.plug.name),
    {
      revalidateOnFocus: false,
    },
  )

  const reloadVersions = () => {
    versions.mutate()
  }

  useEffect(() => {
    if (!data) return
    const pluginOwnershipStatus =
      get(data, 'details.draft', '') === false
        ? PluginStatusOwnership.PUBLISHED
        : get(data, 'details.createdBy', '') === sub
        ? PluginStatusOwnership.DRAFT_OWNED
        : PluginStatusOwnership.DRAFT_NOT_OWNER

    const name = get(data, 'details.plug.name', '')
    const author = get(data, 'details.plug.metadata.author', '')

    const iconURL = get(data, 'details.plug.metadata.iconURL', '')
    const description = get(data, 'details.plug.metadata.description', '')
    const version = get(data, 'details.plug.version', '1.0.0')
    const script = get(data, 'script', '')
    const deployStatus = get(data, 'details.status')
    const tags = get(data, 'details.plug.metadata.tags', [])
    const isDeprecated = get(data, 'details.deprecated', false)
    const type = get(data, 'details.plug.type')
    const properties = get(data, 'details.plug.interface.input', [])
    const rawData = get(data, 'details.plug.interface.output', [])
    const states = get(data, 'details.plug.interface.states', [])
    const runtime = get(data, 'runtime', {})
    const runtimeName = get(
      data,
      'details.runtime.name',
      get(data, 'details.plug.runtime', ''),
    )
    const runtimeVersion = get(
      data,
      'details.runtime.version',
      get(data, 'details.plug.runtimeVersion', ''),
    )

    const dependenciesInitial = runtimeName.includes('python') ? [] : {}

    let dependencies = get(data, 'dependencies', '')
    if (dependencies === '') {
      dependencies = dependenciesInitial
    }
    const plugVersions = get(versions, 'data.entities', [])

    const draft = plugVersions.find(version => version.draft)

    const draftDetails = {
      hasDraft: !!draft,
      name: draft ? draft.plug.name : '',
      version: draft ? draft.plug.version : '',
    }

    const docsInput = get(data, 'details.plug.metadata.documentation.input', [])
    const docsOutput = get(
      data,
      'details.plug.metadata.documentation.output',
      [],
    )
    const docsStates = get(
      data,
      'details.plug.metadata.documentation.states',
      [],
    )
    const documentation = {
      input: docsInput,
      output: docsOutput,
      states: docsStates,
    }

    const payload = {
      pluginOwnershipStatus,
      name,
      version,
      description,
      author,
      iconURL,
      dependencies,
      script,
      deployStatus,
      type,
      isDeprecated,
      tags,
      states,
      documentation,
      properties,
      rawData,
      runtimeName,
      runtimeVersion,
      runtime,
      versions: plugVersions,
      draftDetails,
      reloadVersions,
    }

    // @ts-expect-error incompatible types
    setInitialState(payload)

    dispatch({
      type: EditorStateType.Replace,
      payload,
    })
  }, [JSON.stringify(data), versions?.data, versions.isLoading])

  const setStateProperty = (property: string, value: any) =>
    dispatch({
      type: EditorStateType.SetStateProperty,
      payload: { property, value },
    })

  const addProperty = (
    name: string,
    type: string,
    required: boolean,
    format?: IPropFormat,
  ) =>
    dispatch({
      type: EditorStateType.AddProperty,
      payload: {
        name,
        dataType: type,
        mandatory: required,
        format,
      },
    })

  const editProperty = (
    oldName: string,
    newName: string,
    type: string,
    required: boolean,
    format?: IPropFormat,
  ) =>
    dispatch({
      type: EditorStateType.EditProperty,
      payload: {
        oldName,
        newName,
        dataType: type,
        mandatory: required,
        format,
      },
    })

  const removeProperty = (name: string) =>
    dispatch({
      type: EditorStateType.RemoveProperty,
      payload: { name },
    })

  const addRawData = (name: string, type: string) =>
    dispatch({
      type: EditorStateType.AddRawData,
      payload: { name, dataType: type },
    })

  const removeRawData = (name: string) =>
    dispatch({
      type: EditorStateType.RemoveRawData,
      payload: { name },
    })

  const addState = (name: string) =>
    dispatch({
      type: EditorStateType.AddState,
      payload: { name },
    })

  const removeState = (name: string) =>
    dispatch({
      type: EditorStateType.RemoveState,
      payload: { name },
    })

  const addDependency = (name: string, version: string, isPython?: boolean) =>
    dispatch({
      type: EditorStateType.AddDependency,
      payload: { name, version, isPython },
    })

  const removeDependency = (name: string, isPython?: boolean) =>
    dispatch({
      type: EditorStateType.RemoveDependency,
      payload: { name, isPython },
    })

  const addTag = (tag: string) =>
    dispatch({
      type: EditorStateType.AddTag,
      payload: tag,
    })

  const removeTag = (tagName: string) =>
    dispatch({
      type: EditorStateType.RemoveTag,
      payload: tagName,
    })

  const setDocumentation = (
    documentationType: string,
    name: string,
    value: string,
  ) => {
    dispatch({
      type: EditorStateType.SetDocumentation,
      payload: {
        documentationType,
        name,
        value,
      },
    })
  }

  const setDescription = (value: string) => {
    dispatch({
      type: EditorStateType.SetDescription,
      payload: {
        value,
      },
    })
  }

  const toggleEditDependency = () =>
    dispatch({ type: EditorStateType.ToggleEditDependency })
  const toggleEditProperty = () =>
    dispatch({ type: EditorStateType.ToggleEditProperty })
  const toggleEditRawData = () =>
    dispatch({ type: EditorStateType.ToggleEditRawData })
  const toggleEditStates = () =>
    dispatch({ type: EditorStateType.ToggleEditStates })

  return {
    ...state,
    initialState,
    currentState: state,
    setInitialState,
    addProperty,
    editProperty,
    removeProperty,

    addRawData,
    removeRawData,

    addState,
    removeState,

    setDocumentation,
    setDescription,

    addDependency,
    removeDependency,

    addTag,
    removeTag,

    toggleEditStates,
    toggleEditProperty,
    toggleEditRawData,
    toggleEditDependency,

    setStateProperty,
    plug: data.details,
    changes,
  }
}

export default usePluginEditor
export const PluginEditor = createContainer(usePluginEditor)
