import { createContainer } from 'unstated-next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import useSWR from 'swr'
import client from '../../../lib/client'
import { WebscriptManifest, WebscriptState } from '~/lib/types'
import ProfileContainer, {
  useProfile,
} from '../../Dashboard/Sidebar/useProfile'
import { useEffect, useState } from 'react'
import _, { cloneDeep, get, isEmpty } from 'lodash-es'
import { ResponseType } from '@waylay/client/dist/resource'
import { CustomFunctionsType, PlugV2Structure } from './Types'
import { useToasts } from 'react-toast-notifications'
import { createFileDownload } from '~/lib/util'
import { EXAMPLE_FUNCTION_KEY } from '~/lib/SwrKeys'
import useSWRMutation from 'swr/mutation'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { EventSourceResponse } from '@waylay/client/dist/util'
import { useMutateSWRPartialKey } from '~/components/Common/SWRHelpers'
import { RuntimeContainer } from './useRuntimes'

enum FileRolesEnum {
  main = 'main',
  manifest = 'manifest',
  project = 'project',
  other = 'other',
}
interface IRoleName {
  role: FileRolesEnum
  title?: string
  name: string
}

export const FUNCTION_SWR_KEY = 'Function'

const formatDependencies = (dependencies, runtime: string) => {
  if (runtime === 'node') {
    return dependencies?.dependencies
  } else if (runtime === 'python') {
    if (!isEmpty(dependencies)) {
      const dependenciesArray = (dependencies as string).split('\n')
      return dependenciesArray.filter(dependency => dependency)
    }
    return dependencies
  } else {
    throw new Error('Unsupported runtime')
  }
}

export const useCustomFunction = () => {
  const { name } = useParams()
  const mutateSWRPartialKey = useMutateSWRPartialKey()
  const { allRuntimes, allRuntimesIsFulfilled } =
    RuntimeContainer.useContainer()

  const { addToast } = useToasts()
  const [isSaveEventPending, setIsSaveEventPending] = useState(false)

  const { search, pathname } = useLocation()

  const searchParams = new URLSearchParams(search)

  const version = searchParams.get('version')

  const type =
    pathname.split('/')[1] === 'plugins'
      ? CustomFunctionsType.PLUGIN
      : CustomFunctionsType.WEBSCRIPT

  const { data, error, isValidating } = useSWR(
    [FUNCTION_SWR_KEY, name, version, allRuntimesIsFulfilled],
    async () => {
      if (allRuntimesIsFulfilled) {
        const customFunction = await fetchCustomFunction(
          name,
          version,
          type,
          mutateSWRPartialKey,
        )

        let runtime =
          allRuntimes &&
          Object.values(allRuntimes)?.find(
            runtime =>
              runtime.name === customFunction.entity.runtime.name &&
              runtime.version === customFunction.entity.runtime.version,
          )
        if (runtime === undefined) {
          const newRuntime = await client.registry.runtimes.get(
            customFunction.entity.runtime.name,
            customFunction.entity.runtime.version,
          )
          runtime = newRuntime.runtime
        }
        const scriptNames = await getScriptNames(
          customFunction.entity[type].name,
          customFunction.entity[type].version,
          false,
          type,
        )

        const details = { ...customFunction.entity }
        const script = (await fetchFile(
          name,
          version ?? customFunction.entity[type].version,
          scriptNames.mainScriptName,
          'json',
          type,
        )) as string

        const dependenciesFile = await fetchFile(
          name,
          version ?? customFunction.entity[type].version,
          scriptNames.projectScriptName,
          'json',
          type,
        )

        let dependencies = {}

        try {
          dependencies = formatDependencies(
            dependenciesFile,
            runtime.archiveFormat,
          )
        } catch {
          addToast('Failed to parse dependencies', { appearance: 'error' })
        }

        const assets = await fetchAdditionalAssets(
          name,
          version ?? customFunction.entity[type].version,
          type,
        )

        details.link = customFunction?._links?.invoke?.href
        return {
          assets: { ...assets },
          details,
          script,
          dependencies,
          runtime,
        }
      }
    },
    {
      revalidateOnFocus: false,
    },
  )

  const loading = !data && !error

  return {
    loading,
    isValidating,
    error,
    data,
    isSaveEventPending,
    setIsSaveEventPending,
  }
}

export const fetchFile = async (
  name: string,
  version = '1.0.0',
  fileName: string,
  responseType: ResponseType = 'json',
  type = 'webscript',
): Promise<string | JSON | Blob> => {
  return await ((await client.registry[type + 's'].getFileContent(
    name,
    version,
    fileName,
    {},
    { responseType },
  )) as Promise<string | JSON>)
}

export const fetchAdditionalAssets = async (
  name: string,
  version: string,
  type = 'webscripts',
) => {
  const data = await client.registry[type + 's'].listContent(name, version)
  const additionalAssets = {}

  if (_.isArray(data.assets) && !isEmpty(data.assets)) {
    data.assets.forEach(async asset => {
      if (asset.role === 'other') {
        additionalAssets[asset.name] = { isInitial: true }
      }
    })
  }

  return additionalAssets
}

export const fetchCustomFunction = (
  name: string,
  version: string = undefined,
  type = 'webscript',
  mutateSWRPartialKey,
) => {
  let promise
  if (version) {
    promise = client.registry[type + 's'].get(name, version)
  } else {
    promise = client.registry[type + 's'].getLatest(name)
  }

  return promise.then(response => {
    const eventSourceParams: EventSourceResponse =
      client.registry.jobs.getEventStreamUrl()

    const eventSource = new EventSourcePolyfill(eventSourceParams.url.href, {
      headers: { Authorization: eventSourceParams.headers.Authorization },
      withCredentials: true,
    })

    eventSource.addEventListener('completed', event => {
      const eventData = JSON.parse(event.data)

      if (
        eventData?.job?.type === 'verify' &&
        eventData?.function?.name === name
      ) {
        mutateSWRPartialKey(FUNCTION_SWR_KEY)

        eventSource.close()
      }
    })

    eventSource.addEventListener('close', () => {
      eventSource.close()
    })

    return response
  })
}

export const verifyWebscriptExists = async (name: string): Promise<boolean> => {
  return await client.registry.webscripts.exists(name)
}

export const useExampleFunction = (type: CustomFunctionsType) => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(decodeURI(search))
  const name = searchParams.get('name')
  const runtimeName = searchParams.get('runtimeName')
  const runtimeVersion = searchParams.get('runtimeVersion')
  const profile = ProfileContainer.useContainer()
  const { allRuntimes, allRuntimesIsFulfilled } =
    RuntimeContainer.useContainer()

  const { addToast } = useToasts()

  const example = useSWR(
    [EXAMPLE_FUNCTION_KEY, allRuntimesIsFulfilled],
    async () => {
      if (allRuntimesIsFulfilled) {
        const scriptNames = await getScriptNames(
          runtimeName,
          runtimeVersion,
          type === CustomFunctionsType.WEBSCRIPT,
        )

        const script = (await getExampleFile(
          runtimeName,
          scriptNames.mainScriptName,
          runtimeVersion,
        )) as string

        const manifest = (await getExampleFile(
          runtimeName,
          scriptNames.manifestName,
          runtimeVersion,
        )) as WebscriptManifest

        manifest.name = name
        manifest.runtimeVersion = runtimeVersion

        const dependenciesFile = await getExampleFile(
          runtimeName,
          scriptNames.projectScriptName,
          runtimeVersion,
        )

        let runtime = Object.values(allRuntimes)?.find(
          runtime =>
            runtime.name === runtimeName && runtime.version === runtimeVersion,
        )
        if (runtime === undefined) {
          const newRuntime = await client.registry.runtimes.get(
            runtimeName,
            runtimeVersion,
          )
          runtime = newRuntime.runtime
        }

        let dependencies = {}

        try {
          dependencies = formatDependencies(
            dependenciesFile,
            runtime.archiveFormat,
          )
        } catch {
          addToast('Failed to parse dependencies', { appearance: 'error' })
        }

        const data = {
          assets: {},
          dependencies,
          script,
          details: {
            [type]: { ...manifest },
          },
          runtime,
        }
        return data as unknown as WebscriptState | PlugV2Structure
      }
    },
    {
      revalidateOnFocus: false,
    },
  )

  useEffect(() => {
    if (
      !profile.isLoading &&
      profile?.data &&
      !example.isLoading &&
      example?.data
    ) {
      const data = cloneDeep(example.data)
      data.details[type].metadata.author = profile.data?.fullName
      example.mutate(data, { revalidate: false })
    }
  }, [profile, example])

  return {
    example,
  }
}

export const getExampleFile = async (
  runtime: string,
  path: string,
  version: string,
): Promise<string | WebscriptManifest> => {
  return await ((await client.registry.runtimes.example.getFileContent(
    runtime,
    version,
    path,
  )) as Promise<string | WebscriptManifest>)
}

const getExFileParams = async (
  runtime: string,
  version: string,
): Promise<IRoleName[]> => {
  return await ((
    await client.registry.runtimes.example.listContent(runtime, version)
  ).assets as Promise<IRoleName[]>)
}

const getFunctionFileParams = async (
  plugName: string,
  plugVersion: string,
  type,
): Promise<IRoleName[]> => {
  return await ((
    await client.registry[`${type}s`].listContent(plugName, plugVersion)
  ).assets as Promise<IRoleName[]>)
}

export const getScriptNames = async (
  name: string,
  version: string,
  isExample: boolean = false,
  type: CustomFunctionsType = CustomFunctionsType.PLUGIN,
) => {
  let mainScriptName: string, projectScriptName: string, manifestName: string
  const files = isExample
    ? await getExFileParams(name, version)
    : await getFunctionFileParams(name, version, type)
  files.forEach(roleName => {
    if (roleName.role === FileRolesEnum.main) {
      mainScriptName = roleName.name
    } else if (roleName.role === FileRolesEnum.manifest) {
      manifestName = roleName.name
    } else if (roleName.role === FileRolesEnum.project) {
      projectScriptName = roleName.name
    }
  })
  return { mainScriptName, manifestName, projectScriptName }
}

export const getValidationSchema = async (
  runtimeName: string,
  runtimeVersion: string,
  assetRole,
) => {
  return await client.registry.runtimes.getSchema(
    runtimeName,
    runtimeVersion,
    assetRole,
  )
}

export const useCreateFunction = (
  isNew: boolean,
  type: CustomFunctionsType = CustomFunctionsType.WEBSCRIPT,
) => {
  const { addToast } = useToasts()

  return useSWRMutation(
    'create-function',
    async (
      _,
      { arg }: { arg: { data: Blob | string | FormData; isLegacy?: boolean } },
    ) => {
      const { data, isLegacy } = arg
      let response = {}
      if (isLegacy) {
        response = await client[type + 's'].create(data)
      } else {
        const params =
          type === CustomFunctionsType.PLUGIN && isNew ? { draft: true } : {}
        response = await client.registry[type + 's'].create(data, params)
      }

      return response
    },
    {
      onSuccess: () =>
        addToast(
          isNew
            ? `Successfully created ${
                type === CustomFunctionsType.WEBSCRIPT ? 'webscript' : 'plugin'
              }`
            : `Successfully updated ${
                type === CustomFunctionsType.WEBSCRIPT ? 'webscript' : 'plugin'
              }`,
          { appearance: 'success' },
        ),

      onError: e => {
        const message =
          get(e, 'response.data.message') ?? get(e, 'response.data.error')
        addToast(`Something went wrong: ${message}`, { appearance: 'error' })
      },
      throwOnError: false,
    },
  )
}

export const useCopyFunction = () => {
  const profile = useProfile()
  return useSWRMutation(
    'copy-function',
    async (
      _,
      {
        arg,
      }: { arg: { name: string; sourceName: string; sourceVersion: string } },
    ) => {
      const { name, sourceName, sourceVersion } = arg
      const response = await client.registry.plugs.create(undefined, {
        draft: true,
        name,
        author: profile?.data?.fullName,
        version: '1.0.0',
        copy: `${sourceName}@${sourceVersion}`,
      })
      return response
    },
  )
}

interface createParams {
  name: string
  author: string
  version: string
  runtime: string
  copy: string
  draft?: boolean
}

export const useCreateFromExample = () => {
  const profile = useProfile()
  return useSWRMutation(
    'create-from-example',
    async (
      _,
      {
        arg,
      }: { arg: { name: string; runtime: string; type: CustomFunctionsType } },
    ) => {
      const { name, runtime, type } = arg
      const queryParameters: createParams = {
        name,
        author: profile?.data?.fullName,
        version: '1.0.0',
        runtime,
        copy: `!example`,
      }

      if (type === CustomFunctionsType.PLUGIN) {
        queryParameters.draft = true
      }
      const response = await client.registry[type + 's'].create(
        undefined,
        queryParameters,
      )
      return response
    },
  )
}

export const useCreateDraft = () => {
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const profile = useProfile()

  return useSWRMutation(
    'create-draft',
    async (
      _,
      {
        arg,
      }: {
        arg: {
          sourceName: string
          sourceVersion: string
          newVersion: string
        }
      },
    ): Promise<{ name: string; version: string }> => {
      const { sourceName, sourceVersion, newVersion } = arg
      await client.registry.plugs.create(undefined, {
        draft: true,
        author: profile?.data?.fullName,
        version: newVersion,
        copy: `${sourceName}@${sourceVersion}`,
      })
      return {
        name: sourceName,
        version: newVersion,
      }
    },
    {
      onSuccess: (res: { name: string; version: string }) => {
        addToast('Successfully created draft', { appearance: 'success' })
        navigate(`/plugins/sensors/${res.name}?version=${res.version}`, {
          replace: true,
        })
      },
      onError: e => {
        const message =
          get(e, 'response.data.message') ?? get(e, 'response.data.error')
        addToast(`Something went wrong: ${message}`, { appearance: 'error' })
      },
      throwOnError: false,
    },
  )
}

export const usePublishFunction = () => {
  return useSWRMutation(
    'publish-function',
    async (
      _,
      { arg }: { arg: { name: string; version: string; comment: string } },
    ) => {
      const { name, version, comment } = arg
      const response = await client.registry.plugs.publishDraft(
        name,
        version,
        {},
        {
          comment,
        },
      )
      return response
    },
  )
}

export const useUpdateDraftAssets = () => {
  const { addToast } = useToasts()

  return useSWRMutation(
    'update-draft-assets',
    async (
      _,
      { arg }: { arg: { name: string; version: string; data: any } },
    ) => {
      const { name, version, data } = arg
      addToast('Updating draft...', { appearance: 'info' })
      return await client.registry.plugs.updateDraftAssets(name, version, data)
    },

    {
      onSuccess: res => {
        return res
      },
    },
  )
}

export const useUpdateMetadata = (type: CustomFunctionsType) => {
  const { addToast } = useToasts()

  return useSWRMutation(
    'update-metadta',
    async (
      _,
      { arg }: { arg: { name: string; version: string; metadata: any } },
    ) => {
      const { name, version, metadata } = arg
      await client.registry[type + 's'].patchMetadata(name, version, metadata)
      return metadata
    },
    {
      onSuccess: () =>
        addToast('Successfully updated metadata', { appearance: 'success' }),

      onError: e => {
        const message =
          get(e, 'response.data.message') ?? get(e, 'response.data.error')
        addToast(`Something went wrong: ${message}`, { appearance: 'error' })
      },
      throwOnError: false,
    },
  )
}

export const useDownload = (type: CustomFunctionsType) => {
  const { addToast } = useToasts()

  return useSWRMutation(
    'download-type',
    async (
      _,
      { arg }: { arg: { name: string; version: string; plugType?: string } },
    ) => {
      const { name, version, plugType } = arg
      const response = await client.registry[type + 's'].content(
        name,
        version,
        {},
        {
          headers: { Accept: 'application/gzip' },
        },
      )

      return { response, type, version, name, plugType }
    },
    {
      onSuccess: ({ response, type, version, name, plugType }) => {
        const fileName =
          type === CustomFunctionsType.PLUGIN
            ? `${plugType}-${name}-${version}`
            : name
        createFileDownload(`${fileName}.gz`, response, 'application/gzip')
      },
      onError: error => {
        const message = get(error, 'response.data.error', error.message)
        addToast(`Failed to download: ${message}`, { appearance: 'error' })
      },
      throwOnError: false,
    },
  )
}

const fetchPlugVersions = async options => {
  if (options.name) {
    const versions = await client.registry.plugs.listVersions(options.name, {
      includeDeprecated: true,
      limit: options.limit,
      page: options.page,
    })
    return { count: versions.count, versions: versions.entities ?? [] }
  } else {
    return { count: 0, versions: [] }
  }
}

export const PLUGIN_VERSIONS = 'PLUGINS_VERSIONS'

export const usePluginVersions = options => {
  const { limit, page, name } = options ?? {}
  const key = [PLUGIN_VERSIONS, name, limit, page]
  const { isValidating, data, error } = useSWR(
    key,
    () => fetchPlugVersions(options),
    { revalidateOnFocus: false, revalidateOnMount: false },
  )

  return {
    loading: isValidating,
    error,
    versions: data?.versions,
    count: data?.count,
  }
}

export const useTakeOwnership = () => {
  return useSWRMutation(
    'take-ownership',
    async (
      _,
      {
        arg,
      }: {
        arg: { name: string; version: string; fileName: string; data: any }
      },
    ) => {
      const { name, version, fileName, data } = arg
      const response = await client.registry.plugs.updateDraftAsset(
        name,
        version,
        fileName,
        data,
        {
          chown: true,
        },
      )
      return response
    },
  )
}

export const CustomFunctionContainer = createContainer(useCustomFunction)
