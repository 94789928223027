import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import PropertyInput from '~/components/Tasks/Editor/PropertyInput'
import { Segment, Button, Modal } from '@waylay/react-components'
import { PropertyFormatType } from '~/lib/types'
import { css } from '@emotion/core'
import { cloneDeep, isEmpty } from 'lodash-es'
import {
  Descriptor,
  DescriptorExecutionMode,
  DescriptorExecutionOutput,
} from '@waylay/genai-tools/dist/types/types'
import { TitleRight } from '../Debug'
import { descriptor as aiToolsDescriptor } from '@waylay/genai-tools'
import { SmallErrorMessage } from '~/components/Settings/Settings.styles'
import DescriptorParameters from './DescriptorParameters'
import { PLUGIN_EXCLUDED, TEMPLATE_EXCLUDED } from '~/lib/Constants'
import { useToasts } from 'react-toast-notifications'

interface IDescriptorPropertiesProps {
  setFieldValue: Function
  setShouldShowDescriptor?: Function
  descriptor: Descriptor
  shouldShowDescriptor?: boolean
  descriptorName: string
  property: any
}

const DescriptorProperties = ({
  setFieldValue,
  setShouldShowDescriptor,
  descriptor,
  shouldShowDescriptor,
  descriptorName,
  property,
}: IDescriptorPropertiesProps) => {
  const [editableDescriptor, setEditableDescriptor] = useState(descriptor)
  const [error, setError] = useState('')
  const [maxHeight, setMaxHeight] = useState(300)
  const { addToast } = useToasts()

  useEffect(() => {
    setEditableDescriptor(descriptor)
  }, [descriptor])

  useEffect(() => {
    const updateMaxHeight = () => {
      const viewportHeight = window.innerHeight
      const calculatedMaxHeight = viewportHeight * 0.7
      setMaxHeight(calculatedMaxHeight)
    }

    updateMaxHeight()
    window.addEventListener('resize', updateMaxHeight)

    return () => {
      window.removeEventListener('resize', updateMaxHeight)
    }
  }, [])

  const setDescriptorParameters = (
    parameterName: string,
    field: string,
    value: string,
  ) => {
    const newDescriptor = cloneDeep(editableDescriptor)
    const newParameters = newDescriptor.parameters
    const index = newParameters.findIndex(param => param.name === parameterName)

    let newValue = value

    if (!(newParameters[index].type === 'array')) {
      if (field === 'overrideValue' && isEmpty(value)) {
        newValue = undefined
      }
    }
    //@ts-expect-error
    newParameters[index] =
      field === 'overrideValue'
        ? {
            ...newParameters[index],
            [field]: newValue,
          }
        : field === 'items'
        ? {
            ...newParameters[index],
            items: {
              ...newParameters[index].items,
              type: newValue,
            },
          }
        : {
            ...newParameters[index],
            [field]: {
              ...newParameters[index][field],
              value: newValue,
            },
          }

    if (error) {
      setError('')
    }
    setEditableDescriptor(newDescriptor)
  }

  const removeDescriptorParameters = (parameterName: string, field: string) => {
    const newDescriptor = cloneDeep(editableDescriptor)
    const newParameters = newDescriptor.parameters
    const index = newParameters.findIndex(param => param.name === parameterName)
    delete newParameters[index][field]

    setEditableDescriptor(newDescriptor)

    if (error) {
      setError('')
    }
  }

  const errorDivRef = useRef(null)
  const [paddingBottom, setPaddingBottom] = useState(0)

  useEffect(() => {
    if (errorDivRef?.current) {
      setPaddingBottom(errorDivRef?.current?.offsetHeight + 40)
    } else {
      setPaddingBottom(30)
    }
  }, [error])

  const deleteDescriptor = () => {
    setFieldValue(descriptorName, null)
    if (typeof setShouldShowDescriptor === 'function')
      setShouldShowDescriptor(false)
    setError('')
  }

  const changeProperty = (name: string, value: string) => {
    const newDescriptor = cloneDeep(editableDescriptor)
    if (name === 'executionMode' && value === DescriptorExecutionMode.async) {
      delete newDescriptor.executionOutput.value
    }
    if (name === 'executionMode' && value === DescriptorExecutionMode.sync) {
      newDescriptor.executionOutput.value = DescriptorExecutionOutput.result
    }
    newDescriptor[name]
      ? (newDescriptor[name].value = value === '' ? null : value)
      : {}
    setEditableDescriptor(newDescriptor)
    if (error) {
      setError('')
    }
  }

  useEffect(() => {
    try {
      aiToolsDescriptor.validate(editableDescriptor)
      setError('')
    } catch (err) {
      setError(err?.message)
    }
  }, [editableDescriptor])

  const save = () => {
    try {
      aiToolsDescriptor.validate(editableDescriptor)
      setError('')
      setFieldValue(descriptorName, editableDescriptor)
      addToast('Successfully saved descriptor', { appearance: 'success' })
    } catch (err) {
      setError(err?.message)
    }
  }

  const close = () => {
    setEditableDescriptor(descriptor)
    setError('')
    setShouldShowDescriptor(false)
  }

  return (
    <div>
      {shouldShowDescriptor &&
        !isEmpty(editableDescriptor) &&
        descriptorName && (
          <Segment.Group
            css={css`
              width: 300px;
              align-self: start;
              margin-left: 20px;
              margin-right: 10px;
            `}
          >
            <Segment.Header>
              <Title data-testid="descriptor-title">Descriptor </Title>
              <TitleRight data-testid="descriptor-name">
                {descriptorName}
              </TitleRight>
            </Segment.Header>
            <Segment
              style={{
                maxHeight: maxHeight,
                position: 'relative',
                paddingRight: 0,
              }}
            >
              <div
                style={{
                  maxHeight: `${maxHeight - paddingBottom}px`,
                  overflowY: 'auto',
                }}
              >
                <div style={{ marginRight: '14px' }}>
                  {Object.keys(editableDescriptor ?? [])?.length > 0 &&
                    Object.keys(editableDescriptor)
                      .filter((propertyName: string) => {
                        const excludeFields =
                          property?.format?.type ===
                          PropertyFormatType.AiPluginDescriptor
                            ? PLUGIN_EXCLUDED
                            : TEMPLATE_EXCLUDED

                        return !excludeFields.includes(propertyName)
                      })

                      .map((propertyName: string) => {
                        const isExecutionMode =
                          propertyName === 'executionOutput' &&
                          editableDescriptor.executionMode.value ===
                            DescriptorExecutionMode.async

                        return (
                          <>
                            {!isExecutionMode && (
                              <FormGrid
                                key={`${editableDescriptor[propertyName].name}`}
                              >
                                <Field
                                  data-testid={`${editableDescriptor[propertyName].name}-label`}
                                >
                                  {editableDescriptor[propertyName].name}
                                </Field>
                                <PropertyInput
                                  property={editableDescriptor[propertyName]}
                                  value={editableDescriptor[propertyName].value}
                                  setFieldValue={changeProperty}
                                ></PropertyInput>
                              </FormGrid>
                            )}
                          </>
                        )
                      })}

                  <Title style={{ marginBottom: '8px' }}>Parameters</Title>
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      flexDirection: 'column',
                    }}
                  >
                    {editableDescriptor?.parameters?.length > 0 ? (
                      editableDescriptor?.parameters?.map(parameter => {
                        return (
                          <DescriptorParameters
                            parameter={parameter}
                            setDescriptorParameters={setDescriptorParameters}
                            removeDescriptorParameters={
                              removeDescriptorParameters
                            }
                          />
                        )
                      })
                    ) : (
                      <div data-testid="no-parameters"> No parameters </div>
                    )}
                  </div>
                </div>
              </div>
              {!isEmpty(error) && (
                <div
                  ref={errorDivRef}
                  style={{
                    marginTop: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <SmallErrorMessage
                    style={{ marginRight: '14px' }}
                    data-testid="descriptor-error"
                  >
                    {error}
                  </SmallErrorMessage>
                </div>
              )}
            </Segment>
            <Modal.Actions style={{ zIndex: 9999 }}>
              <Button
                data-testid="close-descriptor-button"
                outline
                kind="secondary"
                onClick={() => close()}
              >
                Close
              </Button>
              <Button
                data-testid="delete-descriptor-button"
                kind="danger"
                onClick={() => deleteDescriptor()}
              >
                Delete
              </Button>
              <Button
                data-testid="save-descriptor-button"
                kind="primary"
                onClick={() => save()}
              >
                Save
              </Button>
            </Modal.Actions>
          </Segment.Group>
        )}
      {shouldShowDescriptor &&
        !isEmpty(descriptorName) &&
        isEmpty(editableDescriptor) && (
          <Segment.Group
            css={css`
              width: 300px;
              align-self: start;
              margin-left: 20px;
              margin-right: 10px;
            `}
          >
            <Segment.Header>
              <Title>Descriptor </Title>
            </Segment.Header>
            <Segment>
              <div data-testid="no-descriptor">No descriptor generated</div>
            </Segment>
          </Segment.Group>
        )}
    </div>
  )
}

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
`

const FormGrid = styled(TwoColumnGrid)`
  grid-column-gap: 0.7em;
  grid-row-gap: 0.5em;
  margin-bottom: 0.5em;
`

const Field = styled.div`
  font-size: 0.9em;
`
const Title = styled.div`
  flex: 1;
  font-weight: bold;
`

export default DescriptorProperties
