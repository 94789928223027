import { LicenseDetails } from '@waylay/client/dist/auth/license'
import { useState } from 'react'
import { createContainer } from 'unstated-next'
import client from '~/lib/client'
import { BreachedMetric, LicenseAlarmType } from '~/lib/types'
import useSWR from 'swr'
import { LICENSE_KEY } from '~/lib/SwrKeys'
import { getDaysDiff } from '~/lib/util'
import { useLogin } from '~/components/App/LoginContext'

interface LicenseError {
  name: string
  message: string
  response: {
    data: {
      status: number
      error: string
      license: LicenseDetails
      metrics: BreachedMetric[]
    }
  }
}

const useLicense = () => {
  const [status, setStatus] = useState<string>()
  const [license, setLicense] = useState<LicenseDetails>(null)
  const [isGenericError, setIsGenericError] = useState(false)
  const [licenseAlarm, setLicenseAlarm] = useState<
    LicenseAlarmType | undefined
  >(undefined)
  const [disabledActions, setDisableActions] = useState(false)
  const [breachedMetrics, setBreachedMetrics] = useState<BreachedMetric[]>([])
  const { token } = useLogin()
  const checkLicenseSoonToExpire = (license: LicenseDetails) => {
    const end = license.end
    const diff = getDaysDiff(end)
    if (diff < 30 && diff > 0) {
      setLicenseAlarm(LicenseAlarmType.EXPIRES_SOON)
    }
  }

  const checkLicenseBreached = (status: number, metrics: BreachedMetric[]) => {
    if (status === 402) {
      setLicenseAlarm(LicenseAlarmType.EXCEEDING_LIMITS)
      const breachedMetricsRes = metrics?.filter(metric => metric.breached)
      setBreachedMetrics(breachedMetricsRes)
    }
    if (status === 400 || status === 404) {
      setLicenseAlarm(LicenseAlarmType.EXPIRED_OR_MISSING)
    }
    setDisableActions(true)
  }

  const licenseState = useSWR(
    [LICENSE_KEY, token],
    async ([_, token]) => {
      if (token) {
        return await client.license.getStatus()
      } else {
        return null
      }
    },
    {
      onSuccess: data => {
        if (data) {
          setStatus('Valid')
          setLicense(data.license)
          setIsGenericError(false)
          checkLicenseSoonToExpire(data.license)
          return data
        }
      },
      onError: async (err: LicenseError) => {
        // workaround for incompatible types
        const error = err
        const status = error?.response?.data?.status

        // Specific error statuses: 402, 404
        status === 402 || status === 404
          ? setIsGenericError(false)
          : setIsGenericError(true)

        checkLicenseBreached(status, error?.response?.data?.metrics)
        // All errors except 402 - Payment required are not returning the license information
        setStatus(error?.response?.data?.error)
        setLicense(error?.response?.data?.license)

        return err
      },
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  )

  return {
    status,
    license,
    disabledActions,
    isGenericError,
    licenseAlarm,
    licenseState,
    breachedMetrics,
  }
}

export const LicenseContainer = createContainer(useLicense)
