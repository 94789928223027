import { useEffect, useMemo, useState } from 'react'
import _, {
  assign,
  cloneDeep,
  find,
  get,
  groupBy,
  reduce,
  unset,
} from 'lodash-es'
import { createContainer } from 'unstated-next'
import { useToasts } from 'react-toast-notifications'
import client from '../../../lib/client'
import log from '../../../lib/log'
import QueryStringProvider from '../../../hooks/useQueryString'
import {
  FieldValidationError,
  GlobalValidationError,
  ITaskTemplate,
  ITemplateError,
  IValidationDetailError,
  NodeValidationError,
} from '../types'
import useSWRMutation from 'swr/mutation'

interface IExistingTemplateMetadata {
  name: string
  discoveryTemplate: boolean
  variables: [IVariable]
  taskDefaults: Object
  tags: Record<string, string>
}
interface IVariable {
  name: string
  value?: any
}

interface ITemplateMetadata {
  name: string
  discoveryTemplate: boolean
  variables: [IVariable]
  tags: Record<string, string>
}

interface ICreatedResponse {
  entity: ITaskTemplate
}

function useTemplate() {
  const [existingTemplateMetadata, setExistingTemplateMetadata] =
    useState<IExistingTemplateMetadata>(null)
  const { query, setQueryValue } = QueryStringProvider.useContainer()
  const [shouldRefreshDebug, setShouldRefreshDebug] = useState(false)
  const [variablesState, setVariablesState] = useState({})
  const validationProblems = usevalidationProblems()
  const [hasBeenValidated, setHasBeenValidated] = useState(false)
  const [taskDefaults, setTaskDefaults] = useState({})
  const [templateMetadata, setTemplateMetadata] =
    useState<ITemplateMetadata>(null)

  const { addToast } = useToasts()

  const save = useSWRMutation(
    ['save-template-context'],
    async (_url, { arg }: { arg: { template: any } }) => {
      const { template } = arg
      validationProblems.clear()
      await client.templates
        .create(template, { failOnWarning: false })
        .then((response: ICreatedResponse) => {
          setQueryValue('template', response.entity.name)
          addToast('Successfully saved template', { appearance: 'success' })
        })
    },
    {
      onError: (error: ITemplateError) => {
        log.error({ err: error })
        const message = get(error, ['response', 'data', 'error'], error.message)
        return addToast(
          <span>
            Failed to save template: <br /> {message}
          </span>,
          { appearance: 'error', autoDismiss: true },
        )
      },
      throwOnError: false,
    },
  )
  const update = useSWRMutation(
    ['template-update-key'],
    async (
      _url,
      { arg }: { arg: { template: { name: string; [key: string]: any } } },
    ) => {
      const { template } = arg
      validationProblems.clear()

      await client.templates
        .update(template.name, template, {
          failOnWarning: false,
        })
        .then(res => {
          const template: any = res
          setExistingTemplateMetadata({
            name: template.name,
            discoveryTemplate: template.discoveryTemplate,
            variables: template.variables,
            taskDefaults: template.taskDefaults,
            tags: template.tags,
          })
          addToast('Successfully updated template', { appearance: 'success' })
        })
    },
    {
      onError: (error: ITemplateError) => {
        const message = get(error, ['response', 'data', 'error'], error.message)
        log.error({ err: error })
        return addToast(
          <span>
            Failed to update template: <br /> {message}
          </span>,
          { appearance: 'error', autoDismiss: true },
        )
      },
      throwOnError: false,
    },
  )

  const _parseTemplateMetadata = metadata => {
    const { tags = {} } = metadata

    if (!Array.isArray(tags)) return metadata

    const definedTags = tags.filter(({ key }) => key) // filter out empty tag keys. ('foo': '') is permitted
    const keyValueEntries = definedTags.map(({ key, value }) => [key, value])

    return { ...metadata, tags: Object.fromEntries(keyValueEntries) }
  }

  const _parseToTemplate = graph => {
    return {
      ...graph,
      ..._parseTemplateMetadata(templateMetadata),
      variables: Object.values(variablesState).map((value: any) =>
        _.omit(value, 'value'),
      ),
      taskDefaults: _parseTemplateMetadata(taskDefaults),
    }
  }

  const saveTemplate = (name, newName, graph) => {
    if (!graph) return
    const parsedGraphDefinition = _parseToTemplate({
      ...graph,
      name: newName,
    })
    return name === newName
      ? update.trigger({ template: parsedGraphDefinition })
      : save.trigger({ template: parsedGraphDefinition })
  }

  const getVariableDeclarations = useSWRMutation(
    'get-variable-declarations',
    async (_, { arg }: { arg: { graphExport: any } }) => {
      const { graphExport } = arg
      if (!graphExport) return
      const existingVariables = existingTemplateMetadata?.variables
      const currentVariables = (await client.templates.variables(
        graphExport,
      )) as [IVariable]

      return reduce(
        currentVariables,
        (acc, currentVariable) => {
          const foundVariableState = find(variablesState as any, {
            name: currentVariable.name,
          })

          const foundVariableExisting = find(existingVariables as any, {
            name: currentVariable.name,
          })

          return foundVariableState
            ? [...acc, foundVariableState]
            : foundVariableExisting
            ? [...acc, foundVariableExisting]
            : [...acc, currentVariable]
        },
        [],
      )
    },
    //TODO TEST
    { throwOnError: false },
  )

  useEffect(() => {
    setVariablesState(
      reduce(
        getVariableDeclarations.data,
        (acc, variable) => {
          const varWithValue = variable.value
            ? variable
            : { value: '', ...variable }
          return { ...acc, [variable.name]: varWithValue }
        },
        {},
      ),
    )
  }, [JSON.stringify(getVariableDeclarations.data)])

  useEffect(() => {
    if (!existingTemplateMetadata) return
    const { taskDefaults = {}, ...meta } = existingTemplateMetadata

    setTaskDefaults({ ...taskDefaults })
    setTemplateMetadata({ ...meta })
  }, [existingTemplateMetadata])

  const refreshTemplateValues = () => {
    if (!existingTemplateMetadata) return
    const { taskDefaults = {}, ...meta } = existingTemplateMetadata

    setVariablesState(
      reduce(
        getVariableDeclarations.data,
        (acc, variable) => {
          const varWithValue = variable.value
            ? variable
            : { value: variable.defaultValue, ...variable }
          return { ...acc, [variable.name]: varWithValue }
        },
        {},
      ),
    )

    setTaskDefaults({ ...taskDefaults })
    setTemplateMetadata({ ...meta })
  }

  // Maybe todo, split variable declaration state from the variableState (the "actual" values for the variables)
  const updateVariableDeclaration = variable => {
    const { name } = variable
    setVariablesState(prevState => {
      const oldState = prevState[name]
      const newState = {
        ...prevState,
        [name]: { ...oldState, ...variable },
      }
      return newState
    })
  }

  const updateVariable = variable => {
    const { name, value } = variable
    setVariablesState(prevState => {
      const oldState = prevState[name]
      return {
        ...prevState,
        [name]: { ...oldState, value },
      }
    })
  }

  const templateTitle = decodeURIComponent(query.template ?? '')

  const isLoading = save.isMutating || update.isMutating

  return {
    save,
    update,
    isLoading,

    existingTemplateMetadata,
    setExistingTemplateMetadata,
    templateTitle,

    variablesState,
    updateVariable,
    getVariableDeclarations,
    updateVariableDeclaration,

    ...validationProblems, // contains all validation stuff
    hasBeenValidated,
    setHasBeenValidated,

    taskDefaults,
    setTaskDefaults,

    templateMetadata,
    setTemplateMetadata,
    refreshTemplateValues,
    shouldRefreshDebug,
    setShouldRefreshDebug,
    saveTemplate,
  } as const
}

function usevalidationProblems() {
  const [globalValidationProblems, _setGlobalValidationProblems] = useState<
    GlobalValidationError[]
  >([])
  const [nodeValidationProblems, _setNodeValidationProblems] = useState<
    Record<string, NodeValidationError[]>
  >({})
  const [fieldValidationProblems, _setFieldValidationProblems] = useState<
    Record<string, FieldValidationError[]>
  >({})

  const globalValidationCount = useMemo(
    () => globalValidationProblems?.length,
    [globalValidationProblems],
  )

  const nodeValidationCount = useMemo(
    () => Object.values(nodeValidationProblems).flat().length,
    [nodeValidationProblems],
  )

  const fieldValidationCount = useMemo(
    () => Object.values(fieldValidationProblems).flat().length,
    [fieldValidationProblems],
  )

  const setGlobalValidationProblems = (errors: IValidationDetailError[]) => {
    const globalProblems = errors.filter(
      err => !err.details || Boolean(err.details.cycle),
    )
    _setGlobalValidationProblems(globalProblems)
  }

  const setNodeValidationProblems = (errors: IValidationDetailError[]) => {
    const nodeProblems = errors.filter(
      err =>
        Boolean(err.details) &&
        Boolean(err.details.nodeId) &&
        !err.details.property,
    )
    const nodeErrorRecord = groupBy(nodeProblems, err =>
      err.details.actuatorLabel
        ? err.details.actuatorLabel
        : err.details.nodeId,
    )

    _setNodeValidationProblems(nodeErrorRecord)
  }

  const setFieldValidationProblems = (errors: IValidationDetailError[]) => {
    const fieldProblems = errors.filter(
      err =>
        Boolean(err.details) &&
        Boolean(err.details.nodeId) &&
        Boolean(err.details.property),
    )
    const nodeFieldErrorRecord = groupBy(fieldProblems, err =>
      err.details.actuatorLabel
        ? err.details.actuatorLabel
        : err.details.nodeId,
    )

    _setFieldValidationProblems(nodeFieldErrorRecord)
  }

  const setValidationProblems = (errors: IValidationDetailError[]) => {
    setGlobalValidationProblems(errors)
    setNodeValidationProblems(errors)
    setFieldValidationProblems(errors)
  }

  const clear = () => {
    _setGlobalValidationProblems([])
    _setNodeValidationProblems({})
    _setFieldValidationProblems({})
  }

  const removeNodeErrors = (nodeName: string) => {
    _setNodeValidationProblems(prev => {
      const newNodeProblems = cloneDeep(prev)
      unset(newNodeProblems, nodeName)
      return newNodeProblems
    })

    _setFieldValidationProblems(prev => {
      const newNodeProblems = cloneDeep(prev)
      unset(newNodeProblems, nodeName)
      return newNodeProblems
    })
  }

  const clearFieldError = (nodeName: string, fieldName: string) => {
    _setFieldValidationProblems(prev => {
      const errors = prev[nodeName]
      if (!errors) {
        return prev
      }

      const newErrors = errors.filter(
        err => err.details.property.toLowerCase() !== fieldName.toLowerCase(),
      )
      return assign({}, prev, { [nodeName]: newErrors })
    })
  }

  return {
    globalValidationProblems,
    fieldValidationProblems,
    nodeValidationProblems,
    validationErrorCount:
      globalValidationCount + fieldValidationCount + nodeValidationCount,
    globalValidationCount,
    fieldValidationCount,
    nodeValidationCount,

    setValidationProblems,
    clear,
    clearFieldError,
    removeNodeErrors,
  } as const
}

const container = createContainer(useTemplate)
export default container
