import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import PropertyInput from '~/components/Tasks/Editor/PropertyInput'
import {
  Segment,
  Icon,
  Collapsible,
  Button,
  Tooltip,
  Select,
} from '@waylay/react-components'
import { PARAMETER_EXCLUDED } from '~/lib/Constants'

interface IDescriptorParametersProps {
  removeDescriptorParameters?: Function
  setDescriptorParameters?: Function
  parameter: any
}

const DescriptorParameters = ({
  parameter,
  setDescriptorParameters,
  removeDescriptorParameters,
}: IDescriptorParametersProps) => {
  const initialOverrideValue = useRef(parameter.overrideValue ? true : false)
  const [showOverride, setShowOverride] = useState<boolean>(
    initialOverrideValue.current,
  )

  return (
    <Collapsible key={`parameter-${parameter.name}`}>
      {({ isOpen, toggle, style }) => (
        <Segment.Group>
          <Segment.Header
            aria-expanded={isOpen}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                data-testid={`${parameter.name}-icon`}
                onClick={() => toggle()}
                style={{ cursor: 'pointer' }}
                name={isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
              />
              <Tooltip
                style={{ width: '100%' }}
                placement="top"
                content={
                  <div style={{ wordWrap: 'break-word' }}>{parameter.name}</div>
                }
              >
                <Field
                  data-testid={`${parameter.name}-field`}
                  style={{
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                  onClick={() => toggle()}
                >
                  {parameter.name}
                </Field>
              </Tooltip>
              {parameter.required && (
                <div
                  data-testid={`${parameter.name}-mandatory`}
                  style={{ color: 'red' }}
                >
                  {' '}
                  *
                </div>
              )}
            </div>
            {isOpen &&
              parameter.overrideValue === undefined &&
              !showOverride && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    data-testid={`${parameter.name}-override`}
                    size="small"
                    onClick={() => setShowOverride(true)}
                  >
                    Override
                  </Button>
                </div>
              )}
          </Segment.Header>
          <Segment style={style} padding={isOpen ? undefined : 0}>
            {Object.keys(parameter)
              .filter(
                (parameterName: string) =>
                  !PARAMETER_EXCLUDED.includes(parameterName),
              )
              .map((name: string) => {
                return (
                  <FormGrid key={`parameter-${parameter.name}-${name}`}>
                    <Field data-testid={`${parameter.name}-${name}-field`}>
                      {name}
                    </Field>
                    <PropertyInput
                      testid={`${parameter.name}-${name}`}
                      key={`${parameter.name}-${name}`}
                      property={{
                        ...parameter?.[name],
                        values: parameter[name].type === 'enum' ? ['test'] : [],
                      }}
                      value={parameter[name]?.value}
                      setFieldValue={(name, value) => {
                        setDescriptorParameters(parameter.name, name, value)
                      }}
                    />
                  </FormGrid>
                )
              })}
            {parameter.type === 'array' && parameter?.items && (
              <FormGrid key={`parameter-${parameter.name}-${name}`}>
                <Field data-testid={`${parameter.name}-items-type-field`}>
                  Items type
                </Field>
                <Select
                  options={parameter?.items?.values.map((value: string) => ({
                    value: value,
                    label: value,
                  }))}
                  value={{
                    value: parameter.items.type,
                    label: parameter.items.type,
                  }}
                  onChange={selectedOption => {
                    setDescriptorParameters(
                      parameter.name,
                      'items',
                      selectedOption.value,
                    )
                  }}
                />
              </FormGrid>
            )}
            {showOverride && (
              <FormGridThreeColumn>
                <Field data-testid={`${parameter.name}-override-field`}>
                  Override Value
                </Field>

                <PropertyInput
                  key={`${parameter.name}-overrideValue`}
                  property={parameter}
                  value={parameter?.overrideValue}
                  setFieldValue={(_name, value) => {
                    setDescriptorParameters(
                      parameter.name,
                      'overrideValue',
                      value,
                    )
                  }}
                />
                <Icon
                  data-testid={`${parameter.name}-remove-override`}
                  name="remove_circle_outline"
                  color="red"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    removeDescriptorParameters(parameter.name, 'overrideValue')
                    setShowOverride(false)
                  }}
                />
              </FormGridThreeColumn>
            )}
          </Segment>
        </Segment.Group>
      )}
    </Collapsible>
  )
}

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
`

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
`

const FormGrid = styled(TwoColumnGrid)`
  grid-column-gap: 0.7em;
  grid-row-gap: 0.5em;
  margin-bottom: 0.5em;
`

const Field = styled.div`
  font-size: 0.9em;
`
const FormGridThreeColumn = styled(ThreeColumnGrid)`
  grid-column-gap: 0.7em;
  grid-row-gap: 0.5em;
`

export default DescriptorParameters
