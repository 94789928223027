import { isEmpty, omit } from 'lodash-es'
import client from '../../lib/client'
import { IQueryConfig } from './Editor/DataQueriesTypes'
import { parseDateToISO } from '~/components/Common/Date/TimestampParser'
import useSWRMutation from 'swr/mutation'

export interface IMessage {
  message: string
  level: string
  timestamp: string
  action: string
  category: string
}

interface ITimeSeriesResponse {
  query: any
  data: IDataResponse[]
  messages: IMessage[]
}

interface IDataResponse {
  data: IDataPoints[]
}

// TODO make variadic array length of numbers
type IDataPoints = any

export interface ITimeSeriesAdaptedResponse {
  series: any[]
  warnings: string[]
}

// [timestamp, value]
// TODO typescript variadic array length
const getData = async ({
  queryConfig,
}: {
  queryConfig: IQueryConfig
}): Promise<ITimeSeriesAdaptedResponse> => {
  const { from, until, grouping, window } = queryConfig

  // filter valid queries only
  const seriesToQuery = queryConfig.series.filter(s => s.resource && s.metric)
  if (isEmpty(seriesToQuery)) {
    return { series: [], warnings: [] }
  }

  const queryDefinition = {
    from: parseDateToISO(from),
    until: until === 'now' ? 'now' : parseDateToISO(until),
    window,
    ...(grouping !== 'none' && { freq: grouping }),
    // we have to remove the 'role' from the query
    data: seriesToQuery.map(serie => omit(serie, 'role')),
  }

  const response: ITimeSeriesResponse = await (client.queries.execute(
    queryDefinition,
  ) as Promise<ITimeSeriesResponse>)

  const data = response.data[0]?.data ?? []
  const warnings = convertDataQueryResponseToWarnings(response)

  // [timestamp, 1, 2, 3] -> [[timestamp, 1], [timestamp, 2], [timestamp, 3]]
  const series = queryConfig.series.map((_config, index) => {
    return data.map(([timestamp, ...values]) => [timestamp, values[index]])
  })

  return { series, warnings }
}

interface IUseTimeseriesProps {
  onResolve?: (response: ITimeSeriesAdaptedResponse) => void
  onReject?: (err: Error) => void
}

const useTimeSeries = ({ onResolve, onReject }: IUseTimeseriesProps = {}) =>
  useSWRMutation(
    'use-time-series-key',
    async (_, { arg }: { arg: { queryConfig: any } }) => {
      const { queryConfig } = arg
      return await getData({ queryConfig })
    },
    {
      onSuccess: onResolve,
      onError: onReject,
      throwOnError: false,
    },
  )

// try to extract some useful warnings from query messages, unfortunately we have to resort
// to parsing the error message because the errors do not have a unique code
// the error message is also different for unaggregated requests
function convertDataQueryResponseToWarnings(
  response: ITimeSeriesResponse,
): string[] {
  const messages = response.messages ?? []
  const usefulWarnings = []

  const tooManyDatapoints = messages.find(message => {
    const aggregated = message.message.includes('select more than')
    const unaggregated = message.message.includes(
      'series might contain more data',
    )

    return aggregated || unaggregated
  })

  if (tooManyDatapoints) {
    usefulWarnings.push(
      'The query response resulted in too many datapoints, try selecting a smaller time window or select a larger grouping to limit the number of datapoints.',
    )
  }

  return usefulWarnings
}

export default useTimeSeries
