import React from 'react'
import { Form } from '@waylay/react-components'
import { PropertyType } from '~/lib/types'
import { IPropertyInput } from '../../types'

const NumericField = ({ property, value, setFieldValue }: IPropertyInput) => {
  const { name, mandatory, type, defaultValue } = property

  const allowDigits =
    type === PropertyType.Double || type === PropertyType.Float

  const handleChange = event => {
    setFieldValue(name, event.target.valueAsNumber)
  }

  return (
    <Form.Input.Group fluid>
      <Form.Input
        style={{ width: '100%' }}
        fluid
        required={mandatory && !defaultValue}
        type="number"
        id={name}
        key={name}
        name={name}
        value={value ?? ''}
        placeholder={defaultValue}
        step={allowDigits ? '0.1' : '1'}
        onChange={handleChange}
      />
    </Form.Input.Group>
  )
}

export default NumericField
