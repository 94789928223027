import React from 'react'
import { mutate, useSWRConfig } from 'swr'
import { isEmpty } from 'lodash-es'

interface IPendingProps {
  state: IState
  children?: React.ReactNode
  shouldRevalidate?: boolean
}

interface IRejectedProps {
  state: IState
  children?: React.ReactNode | ((error: Error) => React.ReactNode)
  shouldRevalidate?: boolean
}

interface IFulfilledProps {
  state: IState
  children?: React.ReactNode | ((data: any) => React.ReactNode)
  shouldRevalidate?: boolean
}

export interface IState {
  error?: Error
  data?: any
  loading?: boolean
  isValidating?: boolean
  isLoading?: boolean
  isMutating?: boolean
}

const isLoading = (state: IState, shouldRevalidate = true) => {
  return shouldRevalidate
    ? state?.isLoading || state?.isValidating || state?.isMutating
    : state?.isLoading || state?.isMutating
}

const IfPending = ({
  state,
  children,
  shouldRevalidate = true,
}: IPendingProps) => {
  return isLoading(state, shouldRevalidate) ? <>{children}</> : null
}

const IfRejected = ({
  state,
  children,
  shouldRevalidate = true,
}: IRejectedProps) => {
  const isRejected = state.error && !isLoading(state, shouldRevalidate)
  if (!isRejected) return null
  if (typeof children === 'function') return children(state.error)
  return children
}

const IfFulfilled = ({
  state,
  children,
  shouldRevalidate = true,
}: IFulfilledProps) => {
  const isFulfilled = shouldRevalidate
    ? !state.error && state.data && !state?.isValidating
    : !state.error && state.data && !state.isLoading
  if (!isFulfilled) return null
  if (typeof children === 'function') return children(state.data)
  return children
}

const IFMutationInitial = ({ state, children }: IFulfilledProps) => {
  const isInitial = !state.error && !state.data && !state.isMutating
  if (!isInitial) return null
  if (typeof children === 'function') return children(state.data)
  return children
}

const useMutateSWRPartialKey = () => {
  const { cache } = useSWRConfig()
  const mutateSWRPartialKey = (partialKey: string) => {
    if (cache) {
      const keys = cache?.keys()
      let key = keys?.next()
      while (!key?.done) {
        if (key?.value?.includes(partialKey)) {
          mutate(key?.value)
        }
        key = keys?.next()
      }
    }
  }
  return mutateSWRPartialKey
}

export {
  IfPending,
  IfRejected,
  IfFulfilled,
  useMutateSWRPartialKey,
  IFMutationInitial,
}
