import React from 'react'
import useSWR from 'swr'
import { Helmet } from 'react-helmet-async'
import { createContainer } from 'unstated-next'

import { useLogin } from './LoginContext'
import client from '~/lib/client'

import largeLogo from '~/assets/images/waylay-v2-large.svg'
import squareLogo from '~/assets/images/waylay-v2-small.png'
import defaultFavicon from '~/assets/images/favicon.png'
import { useFlag } from '~/lib/flags'

interface IBrandingSettings {
  logo?: string
  squareLogo?: string
  favicon?: string
  link?: string
  name?: string
  mutate?: Function
}

interface IBrandingResponse {
  branding: IBrandingSettings
  tenantId: string | null
}

const defaultBranding: IBrandingSettings = {
  name: 'Waylay',
  logo: largeLogo,
  squareLogo,
  favicon: defaultFavicon,
}

const initialBranding: IBrandingSettings = {
  name: '',
  logo: '',
  squareLogo: '',
  favicon: '',
}

const fallbackResponse: IBrandingResponse = {
  branding: initialBranding,
  tenantId: null,
}

/**
 * This is the branding container, when the REACT_APP_ENABLE_WHITELABELING is turn on it will
 * fetch branding details from the accounts endpoint and use the logo and favicon
 */
function useBranding(): IBrandingSettings {
  const { token, tenant } = useLogin()
  if (!token) {
    // this prevents a race condition from breaking the application
    return {}
  }

  const whitelabeling = useFlag('whitelabeling', true)

  if (!whitelabeling) {
    return defaultBranding
  }

  const { data, mutate } = useSWR(
    ['branding', token, tenant],
    async ([, , tenant]) => {
      fetchBrandingKey(tenant)
      return await fetchBrandingSettings()
    },
    {
      initialData: fallbackResponse,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  )

  return {
    name: data.branding.name,
    logo: data.branding.logo,
    squareLogo: data.branding.squareLogo,
    favicon: data.branding.favicon,
    mutate,
  }
}

async function fetchBrandingSettings(): Promise<IBrandingResponse> {
  try {
    return await (client.tenants.branding() as Promise<IBrandingResponse>)
  } catch (err) {
    console.warn('Failed to fetch branding configuration', err)
    return fallbackResponse
  }
}

function fetchBrandingKey(tenant: string): string {
  return 'branding:' + tenant
}

export const Head = () => {
  const { name, favicon } = Branding.useContainer()

  return (
    <>
      <Helmet titleTemplate={`%s · ${name}`} defaultTitle="Console">
        <link rel="icon" href={favicon} />
      </Helmet>
    </>
  )
}

const Branding = createContainer(useBranding)

export default Branding
