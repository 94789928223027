import { useFlag } from '~/lib/flags'
import client from '../../lib/client'
import { IAvailablePlugUpgrade, IPlug } from './util'
import useSWR from 'swr'

const fetchVersions = async plugs => {
  const fetchPromises = plugs.map(async ({ name }) => {
    const versions = await client.registry.plugs.listVersions(name, {
      draft: false,
    })

    return { name, versions: versions.entities ?? [] }
  })

  const results = await Promise.all(fetchPromises)

  // -> { "sensor1": ['1.0.0', '1.0.1'] }
  const accumulatePlugVersions = (acc, { name, versions }) => {
    const strVersions = versions.map(version => version.plug.version)
    acc[name] = strVersions

    return acc
  }

  const resultsMapped = results.reduce(accumulatePlugVersions, {})
  return resultsMapped
}

const fetchVersionsV0 = async plugs => {
  const fetch = plugs.map(({ name, type }) =>
    client[`${type}s`].getVersions(name),
  )

  const results = await Promise.all(fetch)

  // -> { "sensor1": ['1.0.0', '1.0.1'] }
  return results.reduce((acc: Object = {}, plugs: IPlug[]) => {
    const name = plugs[0].name
    const versions = plugs.map(p => p.version)

    return {
      ...acc,
      [name]: versions,
    }
  }, {})
}

export default function availablePlugs(
  plugs: IAvailablePlugUpgrade[],
  options,
) {
  const edgeMode = useFlag('edgeMode', false)

  const fetcher = edgeMode ? fetchVersionsV0(plugs) : fetchVersions(plugs)

  return useSWR('fetch-plug-versions', () => fetcher, {
    onSuccess: options.onResolve,
    onError: e => {
      typeof options.onReject === 'function'
        ? options.onReject(e)
        : console.log('Error fetching available plugs:', e)
    },
    revalidateOnFocus: false,
  })
}
