import React from 'react'
import { Settings } from 'luxon'
import { BrowserRouter } from 'react-router-dom'
import { enableMapSet } from 'immer'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Context from './Context'
import { LoginProvider } from './LoginContext'
import Dashboard from '../Dashboard/Dashboard'
import QueryString from '../../hooks/useQueryString'
import SidebarContainer from '../Dashboard/Sidebar/useCollapsed'
import Branding, { Head as BrandingHead } from './Branding'
import 'semantic-ui-css/semantic.min.css'
import { SimulatorContainer } from '../Simulator/useSimulator'
import RunningSimulator from './RunningSimulator'
import { LicenseContainer } from '~/hooks/useLicense'

// enable ES2015 Map and Set
// see https://immerjs.github.io/immer/installation/
enableMapSet()

const App = ({ stylesContainer }) => {
  // set the default locale to en-US for consistent locale strings
  Settings.defaultLocale = 'en-US'

  return (
    // <React.StrictMode>
    <Context stylesContainer={stylesContainer}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <QueryString.Provider>
            <LoginProvider>
              <LicenseContainer.Provider>
                <Branding.Provider>
                  <SimulatorContainer.Provider>
                    <BrandingHead />
                    <RunningSimulator />
                    <SidebarContainer.Provider>
                      <Dashboard />
                    </SidebarContainer.Provider>
                  </SimulatorContainer.Provider>
                </Branding.Provider>
              </LicenseContainer.Provider>
            </LoginProvider>
          </QueryString.Provider>
        </BrowserRouter>
      </DndProvider>
    </Context>
    // </React.StrictMode>
  )
}

export default App
