/* eslint-disable @typescript-eslint/no-misused-promises */
import { useToasts } from 'react-toast-notifications'
import { get, isEmpty } from 'lodash-es'
import { mutate } from 'swr'
import { useLocation, useNavigate } from 'react-router-dom'
import { createFileDownload } from '../../../lib/util'
import client from '../../../lib/client'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useFlag } from '~/lib/flags'
import { useMutateSWRPartialKey } from '~/components/Common/SWRHelpers'
import { PLUGIN_VERSIONS } from '../Common/useCustomFunctions'
import EventSource from 'eventsource'
import { PluginEditor } from './V2/PluginsContext'
import useSWRMutation from 'swr/mutation'

export const PLUGINS_SWR_KEY = 'PLUGINS_LIST'
export enum PlugType {
  Sensor = 'sensors',
  Actuator = 'actuators',
  Transformer = 'transformers',
  Webscripts = 'webscripts',
}

export enum PlugTypeSingular {
  Sensor = 'sensor',
  Actuator = 'actuator',
  Tranformer = 'transformer',
  Webscript = 'Webscript',
  Gate = 'gate',
  Note = 'note',
}

export interface IPlugMetadata {
  description: string
  version: string
  type: string
}

export const isConfigurableNode = ({ type }) =>
  type === PlugTypeSingular.Sensor || type === PlugTypeSingular.Actuator

export const isConfigurableLabelGate = ({ type }) =>
  type === PlugTypeSingular.Gate

  function cleanObject(obj: any): any {
    if (typeof obj !== 'object' || obj === null) return obj

    if (Array.isArray(obj)) {
      return obj
        .map(cleanObject)
        .filter(item => item !== null && !Number.isNaN(item))
    }

    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, value]) => value !== null && !Number.isNaN(value))
        .map(([key, value]) => [key, cleanObject(value)]),
    )
  }

  const runPlugDeferred = async (
    _,
    {
      arg,
    }: {
      arg: {
        type: PlugType | PlugTypeSingular
        name: string
        version: string
        data?: any
      }
    },
  ): Promise<{
    observedState: Object
  }> => {
    const { type, name, version, data } = arg
    const filteredData = data ? cleanObject(data) : {}
    switch (type) {
      case PlugTypeSingular.Sensor:
      case PlugType.Sensor:
        return await (client.sensors.execute(
          name,
          version,
          filteredData || {},
        ) as Promise<{
          observedState: Object
        }>)
      case PlugTypeSingular.Actuator:
      case PlugType.Actuator || 'actuator':
        return await (client.actuators.execute(
          name,
          version,
          filteredData || {},
        ) as Promise<{
          observedState: Object
        }>)
      case PlugTypeSingular.Tranformer:
      case PlugType.Transformer || 'transformer':
        return await (client.transformers.execute(
          name,
          version,
          filteredData || {},
        ) as Promise<{
          observedState: Object
        }>)
      default:
        throw new Error(`No such plug type: ${type}`)
    }
  }

export const createDeferred = async (
  _,
  {
    arg,
  }: {
    arg: {
      type: PlugType
      plug
    }
  },
): Promise<{ entity: any }> => {
  const { type, plug } = arg
  return await (client[type].create(plug) as Promise<{ entity: any }>)
}

const removeDeferred = async (
  addToast: Function,
  type: PlugType,
  name: string,
  force = false,
  edgeMode: boolean,
) =>
  edgeMode
    ? await client[type].remove(name, { force })
    : await client.registry.plugs.removeAll(name, { force }).then(res => {
        if (res?._links?.event?.href) {
          const eventSource = new EventSourcePolyfill(res._links.event.href, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            },
            withCredentials: true,
          })
          eventSource.addEventListener('completed', event => {
            const eventData = JSON.parse(event.data)
            if (
              eventData.job.type === 'batch' &&
              eventData.function.functionType === 'plugs' &&
              eventData.function.name === name
            ) {
              eventSource.close()
              addToast(`Successfully deleted plugin ${name}`, {
                appearance: 'success',
              })
            }
          })
        }
        if (!force) {
          addToast(`Successfully deprecated plugin ${name}`, {
            appearance: 'success',
          })
        }
      })

const removeVersionDeferred = async (
  type,
  name: string,
  version: string,
  force = false,
  onError?: Function,
) =>
  client[type]
    .removeVersion(name, version, { force })
    .then(() => {})
    .catch(error => {
      if (typeof onError === 'function') onError(error)
    })

const removeVersionV2Deferred = async (
  name: string,
  version: string,
  force = false,
) => {
  await client.registry.plugs.remove(name, version, { force })
}

const runScriptDeferred = async (
  _,
  {
    arg,
  }: {
    arg: {
      properties: any[]
      dependencies: object
      script: string
      options?: object
    }
  },
) => {
  const { properties, dependencies, script, options } = arg
  return await client.sensors.debug({
    properties,
    dependencies,
    script,
    ...options,
  })
}

const getEventSource = async () => {
  return client.registry.jobs.getEventStreamUrl({
    type: 'undeploy',
  })
}

const receptDelete = async (
  type,
  version,
  name,
  force,
  pathname,
  addToast,
  reloadVersions,
  mutateSWRPartialKey,
) => {
  if (force) {
    const event = await getEventSource()

    const eventSource = new EventSource(event.url.href, {
      headers: { Authorization: event.headers.Authorization },
      withCredentials: true,
    })

    eventSource.removeEventListener('completed')

    eventSource.addEventListener('completed', event => {
      const eventData = JSON.parse(event.data)
      if (
        eventData?.job?.type === 'undeploy' &&
        eventData?.function?.functionType === 'plugs' &&
        eventData?.function?.name === name &&
        eventData?.function?.version === version
      ) {
        let showVersion = false
        if (pathname.startsWith('/plugins/sensor/')) {
          showVersion = true
        }
        addToast(
          `Successfully deleted plugin ${name} ${showVersion ? version : ''}`,
          {
            appearance: 'success',
          },
        )

        reloadVersions()
        mutateSWRPartialKey(PLUGINS_SWR_KEY)
        mutateSWRPartialKey(PLUGIN_VERSIONS)

        eventSource.removeEventListener('completed')
        eventSource.close()
      }
    })
  }

  await removeVersionV2Deferred(name, version, force)

  if (!force) {
    addToast(
      `Successfully deprecated plugin ${name} ${
        type !== 'draft' ? `version ${version}` : ''
      }`,
      {
        appearance: 'success',
      },
    )
  }
}

function usePlug() {
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const edgeMode = useFlag('edgeMode', false)
  const { pathname } = useLocation()
  const mutateSWRPartialKey = useMutateSWRPartialKey()
  const runPlug = useSWRMutation('run-plug', runPlugDeferred, {
    throwOnError: false,
  })
  const runScript = useSWRMutation('run-script', runScriptDeferred)

  const create = useSWRMutation('create-plug', createDeferred, {
    onSuccess: ({ entity }) => {
      const { type, name, version } = entity
      addToast(`Successfully saved ${type} ${name} v${version}`, {
        appearance: 'success',
      })
      navigate(`/plugins/${`${type}s`}/${name}?version=${version}`, {
        replace: true,
      })
    },
    onError: (error: any) => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to save plugin: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })

  const remove = useSWRMutation(
    'remove',
    async (
      _,
      {
        arg,
      }: {
        arg: {
          type: PlugType
          name: string
          force: boolean
          edgeMode: boolean
        }
      },
    ) => {
      const { type, name, force, edgeMode } = arg
      return await removeDeferred(addToast, type, name, force, edgeMode)
    },
  )

  const { reloadVersions } =
    pathname.startsWith('/plugins/sensors') && !edgeMode
      ? PluginEditor.useContainer()
      : { reloadVersions: () => {} }

  const removeVersion = useSWRMutation(
    'remove-version',
    async (
      _,
      {
        arg,
      }: {
        arg: {
          type: PlugType
          name: string
          force: boolean
          version: string
          onError?: Function
        }
      },
    ) => {
      const { type, name, force, version, onError } = arg
      return edgeMode
        ? await removeVersionDeferred(type, name, version, force, onError)
        : await receptDelete(
            type,
            version,
            name,
            force,
            pathname,
            addToast,
            reloadVersions,
            mutateSWRPartialKey,
          )
    },
  )

  const upload = useSWRMutation(
    'upload',
    async (
      _,
      {
        arg,
      }: {
        arg: {
          file: any
          fileName: string
        }
      },
    ) => {
      const { file, fileName } = arg
      if (edgeMode || fileName.includes('.json')) {
        const data = JSON.parse(file)
        const plugType = `${data.type}s`
        return client[plugType].create(data)
      }
      return await client.registry.plugs.create(file, { draft: true })
    },
    {
      onSuccess: ({ entity }) => {
        const name = entity?.plug?.name ?? entity?.name
        const type = entity?.plug?.type ?? entity?.type
        const version = entity?.plug?.version ?? entity?.version

        addToast(`Successfully uploaded ${type} ${name} ${version}`, {
          appearance: 'success',
        })

        // Reload plugs
        return mutate(`${type}s`)
      },
      onError: (error: any) => {
        const message = get(error, 'response.data.error', error.message)
        addToast(`Failed to upload: ${message}`, { appearance: 'error' })
      },
      throwOnError: false,
    },
  )

  const download = useSWRMutation(
    'download',
    async (
      _,
      {
        arg,
      }: {
        arg: {
          type: string
          name: string
          version: string
        }
      },
    ) => {
      const { type, name, version } = arg
      return client[type]
        .getScript(name, version)
        .then(data => ({ type, name, version, data }))
    },
    {
      onSuccess: ({ type, name, version, data }) => {
        const filename = `${type}-${name}-${version}.json`
        const json = JSON.stringify(data, null, 2)

        createFileDownload(filename, json)
      },
      onError: (error: any) => {
        const message = get(error, 'response.data.error', error.message)
        addToast(`Failed to download: ${message}`, { appearance: 'error' })
      },
      throwOnError: false,
    },
  )

  const getPlug = useSWRMutation('get-plug-key', fetchPlug)

  const getPlugV2 = useSWRMutation('get-plug-v2-key', fetchPlugV2)

  const updateMetadata = useSWRMutation(
    'update-metadaa',
    async (
      _,
      {
        arg,
      }: {
        arg: {
          type: string
          name: string
          version: string
          metadata: any
        }
      },
    ) => {
      const { type, name, version, metadata } = arg
      return await (client[type].patchMetadata(
        name,
        version,
        metadata,
      ) as Promise<any>)
    },
    {
      onSuccess: () => {
        addToast('Successfully updated metadata', { appearance: 'success' })
      },
      onError: error => {
        const message = get(error, 'response.data.error', error.message)
        addToast(`Failed to update metadata: ${message}`, {
          appearance: 'error',
        })
      },
      throwOnError: false,
    },
  )

  const updateDocumentation = useSWRMutation(
    'update-documentation',
    async (
      _,
      {
        arg,
      }: {
        arg: {
          type: string
          name: string
          version: string
          documentation: any
        }
      },
    ) => {
      const { type, name, version, documentation } = arg
      return await (client[type].patchDocumentation(
        name,
        version,
        documentation,
      ) as Promise<any>)
    },
    {
      onSuccess: () => {
        addToast('Successfully updated documentation', {
          appearance: 'success',
        })
      },
      onError: error => {
        const message = get(error, 'response.data.error', error.message)
        addToast(`Failed to update documentation: ${message}`, {
          appearance: 'error',
        })
      },
      throwOnError: false,
    },
  )

  async function fetchPlug(
    _,
    { arg }: { arg: { type: string; name: string; version: string } },
  ) {
    const { type, name, version } = arg

    const plug = await client[type].get(name, version)

    if (isEmpty(plug.metadata)) {
      plug.metadata = {
        documentation: {},
      }
    }

    if (isEmpty(plug.metadata?.documentation?.supportedStates)) {
      plug.metadata.documentation.supportedStates = (plug.states ?? []).map(
        state => ({
          name: state,
        }),
      )
    }

    if (isEmpty(plug.metadata?.documentation?.configuration)) {
      plug.metadata.documentation.configuration = (
        plug.configuration ?? []
      ).map(entry => ({
        name: entry.name,
      }))
    }

    if (isEmpty(plug.metadata?.documentation?.rawData)) {
      plug.metadata.documentation.rawData = (plug.rawData ?? []).map(entry => ({
        name: entry.parameter,
      }))
    }

    return await Promise.all([
      client[type].getScript(name, plug.version),
      client[type].getVersions(name, { includeDeprecated: true }),
    ]).then(([code, versions]) => ({
      metadata: plug,
      code,
      versions,
    }))
  }

  async function fetchPlugV2(
    _,
    { arg }: { arg: { name: string; version: string; data: any } },
  ) {
    const { name, version, data } = arg
    return await client.registry.plugs.get(name, version, data)
  }

  return {
    create,
    remove,
    removeVersion,
    runPlug,
    runScript,
    upload,
    download,
    updateMetadata,
    updateDocumentation,
    get: getPlug,
    getPlugV2,
  }
}

export const verifyPlugV0Exists = async ([name, type]: [
  string,
  string,
]): Promise<boolean> => {
  return client[type]
    .get(name, 'latest', { includeDeprecated: true })
    .then(() => true)
    .catch(() => false)
}

export const verifyPlugV2Exists = async ([name]): Promise<boolean> => {
  return await client.registry.plugs.exists(name)
}

export const useUpdatePlugInterface = () => {
  const { addToast } = useToasts()

  return useSWRMutation(
    'update-plug-interface',
    async (
      _,
      { arg }: { arg: { name: string; version: string; plugInterface: any } },
    ) => {
      const { name, version, plugInterface } = arg
      await client.registry.plugs.patchInterface(name, version, plugInterface)
      return plugInterface
    },
    {
      onSuccess: () =>
        addToast('Successfully updated plugin interface', {
          appearance: 'success',
        }),

      onError: e => {
        const message =
          get(e, 'response.data.message') ?? get(e, 'response.data.error')
        addToast(`Something went wrong: ${message}`, { appearance: 'error' })
      },
      throwOnError: false,
    },
  )
}
export default usePlug
