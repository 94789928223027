import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { DateTime } from 'luxon'
import { get, reject } from 'lodash-es'
import client from '~/lib/client'
import useTimeSeries from './useTimeseries'
import { generateNewColor } from './Editor/Colors'
import { v4 as uuidv4 } from 'uuid'
import {
  Aggregation,
  SeriesTypes,
  Grouping,
  IDataQueryResponse,
  IDataTypes,
  IQueryConfig,
  IQueryStringParams,
  ISerie,
} from './Editor/DataQueriesTypes'
import { ChartContainer } from './Editor/ChartContainer'
import { fetchResourcesByIds } from '~/hooks/useResourcesList'
import { isNumeric } from '~/lib/util'
import {
  resolveTimeDefinition,
  getInterval as getTimeInterval,
  toFormattedDate,
  formatDate,
} from '~/components/Common/Date/TimestampParser'
import useSWRMutation from 'swr/mutation'

const findResourcesByIds = async (ids: String[]) => {
  const resources = await fetchResourcesByIds(ids)

  return get(resources, '_embedded.values', []).reduce(
    (resourcesNamesMappings, resource) => {
      resourcesNamesMappings[resource.id] = resource.name ?? resource.id
      return resourcesNamesMappings
    },
    {},
  )
}

const DEFAULT_GROUPING = Grouping.None
const DEFAULT_AGGREGATION = Aggregation.Mean
const DEFAULT_WINDOW = 'P14D'

const getDataQuery = (queryName: string) => {
  if (!queryName) return Promise.resolve({})
  return client.queries.get(queryName)
}

const EMPTY_SERIE = {
  resource: '',
  metric: '',
  aggregation: DEFAULT_AGGREGATION,
  seriesId: uuidv4(),
}

const EMPTY_QUERY_CONFIG: IQueryConfig = {
  grouping: DEFAULT_GROUPING,
  until: 'now',
  window: DEFAULT_WINDOW,
  series: [EMPTY_SERIE],
}

function convertDataQueryResponseToConfig(
  response: IDataQueryResponse,
): IQueryConfig {
  const { query } = response
  const absoluteWindow = query.from && query.until

  return {
    from: formatDate(query.from),
    until: formatDate(query.until),
    window: absoluteWindow ? undefined : query.window ?? DEFAULT_WINDOW,
    grouping: query.freq ?? DEFAULT_GROUPING,
    series: query.data.map(config => ({
      ...config,
      aggregation: config.aggregation ?? query.aggregation,
      resource: config.resource ?? query.resource,
      metric: config.metric ?? query.metric,
      seriesId: config.seriesId ?? uuidv4(),
    })),
  }
}

// query string params can be string | string[]
const firstParam = (param: string | string[]): string => [].concat(param)[0]

function convertQueryStringToQueryConfig(query: string): IQueryConfig {
  const parsed: IQueryStringParams = queryString.parse(query)
  const from = formatDate(firstParam(parsed.from))
  const until = formatDate(firstParam(parsed.until))

  const absoluteWindow = from && until

  return {
    from,
    until,
    window: absoluteWindow
      ? undefined
      : firstParam(parsed.window) ?? DEFAULT_WINDOW,
    grouping: (firstParam(parsed.grouping) as Grouping) ?? DEFAULT_GROUPING,
    series: parsed.series
      ? JSON.parse(parsed.series)
      : [
          {
            resource: '',
            metric: '',
            aggregation: DEFAULT_AGGREGATION,
            seriesId: uuidv4(),
          },
        ],
  }
}

function useExplorer() {
  const location = useLocation()
  const search = location.search || ''
  const initialState = convertQueryStringToQueryConfig(search)
  const [queryConfig, setQueryConfig] = useState<IQueryConfig>(initialState)
  const [metricColor, setMetricColor] = useState<object>({})
  const [warnings, setWarnings] = useState<string[]>([])
  const parsedQueryString = queryString.parse(search)
  const { modified, queryName: qsName } = parsedQueryString
  const {
    transformChart,
    updateSerieForAllCharts,
    removeSerieFromAllCharts,
    createChartsForPreviousVersion,
  } = ChartContainer.useContainer()
  const [resourcesNames, setResourcesNames] = useState<object>({})
  const [seriesTypes, setSeriesTypes] = useState([])

  const queryName = [].concat(qsName)[0]
  const queryMode = queryName
  const modifiedQueryMode = queryMode && modified === 'true'
  const blankMode = search === ''

  useEffect(() => {
    if (!queryName && !blankMode) {
      const resourceIds = []
      queryConfig.series.forEach(serie => {
        if (serie.seriesId) {
          const serieId = serie.seriesId
          resourceIds.push(serie.resource)
          if (!metricColor[serieId]) {
            setMetricColor(prevState => ({
              ...prevState,
              [serieId]: generateNewColor(),
            }))
          }
        }
      })

      findResourcesByIds(resourceIds).then(resources => {
        setResourcesNames(resources)
        createChartsForPreviousVersion(queryConfig)
      })
    }
  }, [])

  const dataQueryState = useSWRMutation(
    'data-query-state',
    async (_, { arg }: { arg: { queryName: string } }) => {
      const { queryName } = arg
      return await getDataQuery(queryName)
    },
    {
      onSuccess: async (response: IDataQueryResponse) => {
        const config = convertDataQueryResponseToConfig(response)
        const resourceIds = []
        config.series.forEach(serie => {
          if (serie.seriesId) {
            const serieId = serie.seriesId
            resourceIds.push(serie.resource)
            if (!metricColor[serieId]) {
              setMetricColor(prevState => ({
                ...prevState,
                [serieId]: generateNewColor(),
              }))
            }
          }
        })
        const resourcesNames = await findResourcesByIds(resourceIds)
        setResourcesNames(resourcesNames)
        if (response?.meta?.console) {
          const charts = response.meta.console.charts
          transformChart(config, charts)
        } else {
          createChartsForPreviousVersion(config)
        }

        setQueryConfig(config)
      },
      onError: (error: Error) => {
        console.error(error)
      },
      throwOnError: false,
    },
  )

  const runQuery = useTimeSeries({
    onResolve: response => {
      setWarnings(response.warnings)
    },
  })

  useEffect(() => {
    const queryConfigCopy = [...(queryConfig?.series ?? [])]
    if (runQuery?.data) {
      const series = runQuery?.data?.series
      const types = []
      if (series && series.length > 0) {
        series?.forEach((serie, index) => {
          const id = queryConfigCopy[index]?.seriesId
          const nonNumericValues = serie?.filter(
            config =>
              !isNumeric(config[1]) &&
              config[1] !== null &&
              config[1] !== undefined,
          )
          let type = SeriesTypes.Numeric
          if (nonNumericValues?.length > 0) {
            type = SeriesTypes.String
          } else {
            type = SeriesTypes.Numeric
          }

          const seriesTypesMappings: IDataTypes = {
            id,
            type,
          }

          types.push(seriesTypesMappings)
        })
      }
      setSeriesTypes(types)
    }
  }, [runQuery.data, queryConfig])

  // Only fetch data query config if queryName changes
  useEffect(() => {
    if (queryName && modified !== 'true') {
      dataQueryState.trigger({ queryName })
    }
  }, [blankMode, modifiedQueryMode])

  // clear config when no queryName
  useEffect(() => {
    if (blankMode) {
      const seriesId = EMPTY_QUERY_CONFIG.series[0].seriesId
      setQueryConfig(EMPTY_QUERY_CONFIG)
      setMetricColor(prevState => ({
        ...prevState,
        [seriesId]: generateNewColor(),
      }))
      setWarnings([])
      createChartsForPreviousVersion(EMPTY_QUERY_CONFIG)
    }
  }, [blankMode])

  const updateConfig = newConfig => {
    // Calculate new config based on previous config
    setQueryConfig(currentConfig => ({
      ...currentConfig,
      ...newConfig,
    }))
  }

  /**
   * when zooming out we will double the current time frame, so 24h -> 48h
   * we will subtract 50% from the startDate and add 50% to the endDate
   */
  function zoomOut() {
    const interval = getInterval()
    const halfOfInterval = { milliseconds: interval.length('milliseconds') / 2 }

    const newFrom = interval.start.minus(halfOfInterval)
    // make sure we don't go past "now" by selecting the smallest date between "now" and the future date
    const newUntil = DateTime.min(
      resolveTimeDefinition('now'),
      interval.end.plus(halfOfInterval),
    )

    updateConfig({
      from: toFormattedDate(newFrom),
      until: toFormattedDate(newUntil),
      window: undefined,
    })
  }

  function zoomIn(from: Date, until: Date) {
    updateConfig({
      from: from.toISOString(),
      until: until.toISOString(),
      window: undefined,
    })
  }

  const updateSeries = (newConfig: IQueryConfig, index: number) => {
    const serieId = queryConfig.series[index].seriesId
    setQueryConfig(currentConfig => ({
      ...currentConfig,
      series: currentConfig.series.map((serie, serieIndex) => {
        if (index !== serieIndex) return serie
        return {
          ...serie,
          ...newConfig,
        }
      }),
    }))
    updateSerieForAllCharts(serieId, newConfig)
  }

  const addSeries = (serie?: ISerie) => {
    const newSerieId = uuidv4()
    if (!metricColor[newSerieId]) {
      setMetricColor(prevState => ({
        ...prevState,
        [newSerieId]: generateNewColor(),
      }))
    }
    if (serie) {
      updateConfig({
        series: [].concat(queryConfig.series).concat({
          ...serie,
          seriesId: `${newSerieId}`,
        }),
      })
    } else {
      updateConfig({
        series: [].concat(queryConfig.series).concat({
          ...EMPTY_SERIE,
          seriesId: `${newSerieId}`,
        }),
      })
    }
  }

  const removeSeries = (seriesIndex: number) => {
    const serieId = queryConfig.series[seriesIndex].seriesId
    const metricColors = { ...metricColor }
    delete metricColors[serieId]
    setMetricColor(metricColors)

    removeSerieFromAllCharts(serieId)
    updateConfig({
      series: reject(
        queryConfig.series,
        (_serie, index) => index === seriesIndex,
      ),
    })
  }

  const getInterval = () => {
    const { from, until, window } = queryConfig
    return getTimeInterval(from, until, window)
  }

  return {
    queryName,
    queryConfig,
    updateConfig,
    updateSeries,
    addSeries,
    removeSeries,
    setResourcesNames,
    zoomOut,
    zoomIn,
    warnings,
    runQuery,
    getInterval,
    resourcesNames,
    dataQueryState,
    metricColor,
    seriesTypes,
  }
}

export default createContainer(useExplorer)
