export interface ISecurityStatus {
  tenant2Fa: boolean
  user2Fa: boolean
}

export interface IApiKey {
  apiKey: string
  apiSecret: string
  description: string
  id: string
}
export interface IStore {
  apiKeys: IApiKey[]
  email: string
  firstName: string
  fullName: string
  organisation: string
  timezone: string
}

export interface IStoreState {
  data: IStore
  error: Error
  mutate: Function
  isValidating: boolean
  isLoading: boolean
}

export interface ISetting {
  key: string
  value?: string
}

export interface IProvider {
  name: string
  prettyName: string
  auth: {
    type: string
    authorizeUrl: string
  }
  isConnected: boolean
}

export interface IRowProps {
  prettyName: string
  name: string
  isConnected: boolean
  state: any
}

export enum EStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  unverified = 'unverified',
}

export interface IUser {
  id: string
  email: string
  firstname: string
  lastname: string
  fullname: string
  timezone: string
  status: EStatus
  password: string
  twofa: boolean
  roles: IRole[]
  /* eslint-disable camelcase */
  created_at: string
  updated_at: string
  last_login: string
  /* eslint-enable camelcase */
}

export interface IRole {
  id?: string
  name?: string
  description: string
  tenant: ITenant
  permissions: IPermission[]
  /* eslint-disable camelcase */
  created_at: string
  updated_at: string
  /* eslint-enable camelcase */
  predefined?: boolean
}

export interface ITenant {
  id: string
  name: string
  domain: string
  description: string
  status: EStatus
  twofa: boolean
}

export interface IPermission {
  id: string
  name: string
  description: string
  scope: EPermissionScope
  operation: EPermissionOperation[]
  concept: string
}

export enum EPermissionScope {
  all = 'all',
  me = 'me',
}

export enum EPermissionOperation {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}

export interface IStateUser {
  email: string
  password: string
  firstname?: string
  lastname?: string
  roles: any[]
}

export interface IExportSchedule {
  domain: string
  format: string
  compressed: boolean
  period: string
  scheduleExpression: string
  timeZone: string
  nextExecution: string
  meta: boolean
  timeseries: boolean
}

export enum EFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export interface IExportScheduleFormValues {
  frequency: EFrequency
  includeMeta: boolean
}

export enum EBillingServices {
  Functions = 'Functions',
  TimeSeries = 'Time Series',
  Storage = 'Storage',
  Alarms = 'Alarms',
  Vault = 'Secrets',
  Broker = 'Messages',
  Tasks = 'Tasks',
  Assets = 'Assets',
  MachineLearning = 'Machine Learning',
  Webscripts = 'Webscripts',
  TotalInvocations = 'Total Invocations',
  Transactions = 'Transactions',
  runningTasks = 'Running Tasks',
}

export enum EBillingSeries {
  alarmsOccurred = 'alarmsOccurred',
  vaultKeys = 'vaultKeys',
  storageTotalBytes = 'storageTotalBytes',
  storageTotalBytesReceived = 'storageTotalBytesReceived',
  storageTotalBytesSent = 'storageTotalBytesSent',
  functionComputeTime = 'functionComputeTime',
  functionExecutions = 'functionExecutions',
  nativeFunctionExecutions = 'nativeFunctionExecutions',
  ingestedDatapoints = 'ingestedDatapoints',
  timeseriesReadScan = 'timeseriesReadScan',
  messagesIngested = 'messagesIngested',
  messagesQueried = 'messagesQueried',
  messagesStorageSize = 'messagesStorageSize',
  taskTransitions = 'taskTransitions',
  resources = 'resources',
  resourceTypes = 'resourceTypes',
  resourceConstraints = 'resourceConstraints',
  resourceStorageSize = 'resourceStorageSize',
  byomlComputeTime = 'byomlComputeTime',
  converterInvocations = 'converterInvocations',
  taskLogsStorage = 'taskLogsStorage',
  webscriptExecutions = 'webscriptExecutions',
  taskInvocations = 'invocationCount',
  templateRunInvocations = 'templateRuns',
  totalInvocations = 'totalInvocations',
  runningTasks = 'runningTasks',
}

export enum EFieldType {
  FIELD = 'field',
  SPACER = 'spacer',
}
