import React from 'react'
import styled from '@emotion/styled'
import {
  carbon,
  concrete,
  Popup,
  List,
  Segment,
  Loader,
  Tooltip,
} from '@waylay/react-components'
import { Link } from 'react-router-dom'
import { IfFulfilled, IfPending } from '../../Common/SWRHelpers'
import { get } from 'lodash-es'
import { useLogin } from '../../App/LoginContext'
import ProfileContainer from './useProfile'
import FakeImage from '../../Common/FakeImage'

const Organisation = styled.div`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Name = styled.div`
  color: ${carbon};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

const Details = styled.div`
  margin-left: 0.5em;
  flex: 1;
  min-width: 0; /* min-width flex ellipsis hack */
`

interface IProfileProps {
  collapsed?: boolean
}

const ProfileWrapper = styled.div`
  padding: 1em;
  display: flex;
  line-height: 1.25em;
  border-top: solid 1px ${concrete};
  width: 100%;
  outline: none;

  user-select: none;
  cursor: pointer;

  &:hover {
    background: #fff;
  }
`

const AvatarWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  overflow: hidden;
`

const Profile = ({ collapsed = false }: IProfileProps) => {
  const { logout } = useLogin()
  const state = ProfileContainer.useContainer()

  return (
    <div style={{ width: '100%' }}>
      <IfPending state={state} shouldRevalidate={false}>
        <ProfileWrapper
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader size={32} />
        </ProfileWrapper>
      </IfPending>
      <IfFulfilled state={state}>
        {({ organisation, fullName }) => (
          <Popup
            placement="right"
            appendTo={() => document.body}
            content={
              <List as={Segment} padding={0} interactive>
                <List.Item nonInteractive>
                  Hello, <strong>{get(state, 'data.firstName')}</strong>
                </List.Item>
                <List.Divider />
                <List.Item as={Link} to="/administration/settings/details">
                  Your Account
                </List.Item>
                <List.Item onClick={() => logout({ withRedirect: false })}>
                  Sign Out
                </List.Item>
              </List>
            }
          >
            <ProfileWrapper>
              <>
                <AvatarWrapper>
                  <FakeImage title={fullName} />
                </AvatarWrapper>
                {!collapsed && (
                  <Details>
                    <Organisation>{organisation}</Organisation>
                    <Tooltip placement="top" content={fullName}>
                      <Name>{fullName}</Name>
                    </Tooltip>
                  </Details>
                )}
              </>
            </ProfileWrapper>
          </Popup>
        )}
      </IfFulfilled>
    </div>
  )
}

export default Profile
